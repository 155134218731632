import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        categoryId: null,
        userId: null,
        userIdData: [],
        totalPrice: 0,
        mobileMenu: false,
        homeData: null,
        brandsData: null,
        searchText: '',
        searchData: [],
        newOrders: [],
        trusteds: null,
        edit_from_admin: null,
        aboutData: null,
        serviceData: null,
        paymentShippingData: null,
        leasingData: null,
        contactData: null,
        compareProduct: null,
        productId: null,
        order: null,
        categories: null,
        activeBrands: [],
        newOrdersPagination: null,
        order_to_card: null
    },
    mutations: {
        CATEGORY_ID(state, payload) {
            state.categoryId = payload
        },
        USER_ID(state, payload) {
            state.userId = payload
        },
        USER_ID_DATA(state, payload) {
            state.userIdData = payload
        },
        USER_TOTAL_PRICE(state, payload) {
            state.totalPrice = payload
        },
        MOBILE_MENU(state, payload) {
            state.mobileMenu = payload
        },
        HOME_DATA(state, payload) {
            state.homeData = payload
        },
        BRAND_DATA(state, payload) {
            state.brandsData = payload
        },
        ORDER_DATA(state, payload) {
            state.order_to_card = payload
        },
        SEARCH_TEXT(state, payload) {
            state.searchText = payload
        },
        NEW_ORDERS(state, payload) {
            state.newOrders.push(...payload.product)
            state.newOrdersPagination = payload.pagination
        },
        TRUSTEDS(state, payload) {
            state.trusteds = payload
        },
        EDIT_FROM_ADMIN(state, payload) {
            state.edit_from_admin = payload
        },
        ABOUT_DATA(state, payload) {
            state.aboutData = payload
        },
        SERVICE_DATA(state, payload) {
            state.serviceData = payload
        },
        PAYMENT_SHIPPING(state, payload) {
            state.paymentShippingData = payload
        },
        LEASING_DATA(state, payload) {
            state.leasingData = payload
        },
        CONTACT_DATA(state, payload) {
            state.contactData = payload
        },
        COMPARE_PRODUCT(state, payload) {
            state.compareProduct = payload
        },
        PRODUCT_ID(state, payload) {
            state.productId = payload
        },
        ORDER(state, payload) {
            state.order = payload
        },
        SEARCH_DATA(state, payload) {
            state.searchData = payload
        },
        CATEGORIES(state, payload) {
            state.categories = payload
        },
        ACTIVEBRANDS(state, payload) {
            state.activeBrands = payload
        }
    },
    actions: {
        async categoryIdRemove(context, payload) {
            context.commit('CATEGORY_ID', payload)
        },
        async userIdRemove(context, payload) {
            context.commit('USER_ID', payload)
        },
        async userIdDataRemove(context, payload) {
            context.commit('USER_ID_DATA', payload)
        },
        async userTotalPriceRemove(context, payload) {
            context.commit('USER_TOTAL_PRICE', payload)
        },
        async mobileMenuRemove(context, payload) {
            context.commit('MOBILE_MENU', payload)
        },
        async homeDataRemove(context) {
            const url = {subDomain: window.location.hostname}
            axios
                .post('/general', url)
                .then((response) => {
                    context.commit('HOME_DATA', response.data)
                });
        },

        async getRegionLists() {
            try {
                let response = await axios.get('/subDomain')
                return response.data;
            } catch (error) {
                return error.response;
            }
        },

        async addToCard(context, payload) {
            try {
                let response = await axios.get('/product/'+ payload)
                context.commit('ORDER_DATA', response.data)
                return response.data;
            } catch (error) {
                return error.response;
            }
        },

        async brandsDataRemove(context) {
            let activeBrands = []
            axios
                .get(`/brand`)
                .then((response) => {
                    context.commit('BRAND_DATA', response.data)
                    response.data.forEach((brand) => {
                        activeBrands.push({
                            brandTitle: brand.title,
                            brandId: brand.id,
                            brandChack: false
                        })
                    })
                    context.commit('ACTIVEBRANDS', activeBrands)
                });
        },
        async searchTextRemove(context, payload) {
            context.commit('SEARCH_TEXT', payload)
        },
        async newOrdersRemove(context, page = 1) {
            axios
                .get(`/product/novelties?page=${page}`)
                .then((response) => {
                    context.commit('NEW_ORDERS', response.data)
                });
        },
        async trustedsRemove(context) {
            axios
                .get(`/trusted`)
                .then((response) => {
                    context.commit('TRUSTEDS', response.data)
                });
        },
        async editFromAdmin(context) {
            let response = await axios.get(`/getLinks`)
            context.commit('EDIT_FROM_ADMIN', response.data)
            return response.data
        },
        async aboutDataRemove(context) {
            if (!this.state.aboutData) {
                axios
                    .get(`/about_company`)
                    .then((response) => {
                        context.commit('ABOUT_DATA', response.data)
                    });
            }
        },
        async serviceDataRemove(context) {
            if (!this.state.serviceData) {
                axios
                    .get(`/service`)
                    .then((response) => {
                        context.commit('SERVICE_DATA', response.data)
                    });
            }
        },
        async paymentShippingDataRemove(context) {
            if (!this.state.paymentShippingData) {
                axios
                    .get(`/payment_shipping`)
                    .then((response) => {
                        context.commit('PAYMENT_SHIPPING', response.data)
                    });
            }
        },
        async leasingDataRemove(context) {
            if (!this.state.leasingData) {
                axios
                    .get(`/leasing`)
                    .then((response) => {
                        context.commit('LEASING_DATA', response.data)
                    });
            }
        },
        async contactDataRemove(context) {
            if (!this.state.contactData) {
                axios
                    .get(`/contacts`)
                    .then((response) => {
                        context.commit('CONTACT_DATA', response.data)
                    });
            }
        },
        async compareProductRemove(context, payload) {
            context.commit('COMPARE_PRODUCT', payload)
        },
        async productIdRemove(context, payload) {
            context.commit('PRODUCT_ID', payload)
        },
        async orderRemove(context, payload) {
            context.commit('ORDER', payload)
        },
        async searchDataRemove(context, payload) {
            if (typeof payload === "object") {
                context.commit('SEARCH_DATA', payload)
                return
            }
            axios
                .get(`/product/search?search=${payload}`)
                .then((response) => {
                    context.commit('SEARCH_DATA', response.data)
                });
        },
        async categoriesRemove(context) {
            if (!this.state.categories) {
                axios
                    .get(`/category`)
                    .then((response) => {
                        context.commit('CATEGORIES', response.data)
                    });
            }
        }

    },
    getters: {
        getOrder(state) {
            return state.user;
        },
    }
})
