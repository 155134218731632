<template>
  <div>
    <Spiner
        v-if="!paymentShippingData"
    />
    <SubPage
        v-if="paymentShippingData"
        :subPageData="paymentShippingData"
    />
  </div>
</template>

<script>
import SubPage from "@/components/global-component/SubPage";
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import Spiner from "@/components/global-component/Spiner";
export default {
  name: "PaymentShipping",
  components: {
    SubPage,
    Spiner
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapActions(['paymentShippingDataRemove'])
  },
  computed: {
    ...mapState(['paymentShippingData'])
  },
  mounted() {
    window.scrollTo(0, 0)
    this.paymentShippingDataRemove()
  }
}
</script>

<style scoped>

</style>
