<template>
  <div id="app">
    <div>
      <Header/>
      <MenuMob/>
      <router-view/>
      <Footer/>
    </div>
  </div>
</template>
<script>
import Header from "@/components/global-component/Header";
import Footer from "@/components/global-component/Footer";
import MenuMob from "@/components/menu/MenuMob";
export default {
  name: 'App',
  components: {
    MenuMob,
    Header,
    Footer
  },


}
</script>
<style lang="scss">
@import "@/assets/scss/app.scss";

</style>
