<template>
<modal
    name="back-call"
    class="back-call"
    :width="'auto'"
    :height="'auto'"
>
  <div class="form-l">
    <img
        src="@/assets/images/close-popup.png"
        class="form-l__close"
        @click="$modal.hide('back-call')"
    >
    <div class="form-l__title">Обращение в ЭквипДон</div>
    <div class="form-l__label">Имя</div>
    <div class="form-l__input">
      <input
          type="text"
          name="name"
          v-model="backCallData.name"
          :class="{'w-add-sale__input-error' : $v.backCallData.name.$dirty && $v.backCallData.name.$error}"
      >
    </div>
    <div class="form-l__label">Телефон*</div>
    <div class="form-l__input">
      <input
          type="text"
          name="theme"
          v-model="backCallData.tel"
          :class="{'w-add-sale__input-error' : $v.backCallData.tel.$dirty && $v.backCallData.tel.$error}"
      >
    </div>
    <div class="form-l__label">Сообщение</div>
    <div class="form-l__input">
      <input
          type="text"
          name="sms"
          v-model="backCallData.message"
          :class="{'w-add-sale__input-error' : $v.backCallData.message.$dirty && $v.backCallData.message.$error}"
      >
    </div>
    <button
        class="form-l__btn"
        @click="getBackCallData"
    >Отправить</button>
    <p class="form-l__text">
      Нажимая кнопку "Отправить", я подтверждаю, что ознакомлен и согласен с условиями Договора-оферты и даю согласие на обработку моих персональных данных для оформления заказа
    </p>
  </div>
</modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
export default {
  name: "BackCall",
  mixins: [validationMixin],
  data () {
    return{
      backCallData: {
        name: '',
        tel: '',
        message: ''
      }
    }
  },
  validations: {
    backCallData: {
      name: {
        required
      },
      tel: {
        required,
        numeric
      },
      message: {
        required
      }
    }
  },
  methods: {
    async getBackCallData () {
      this.$v.backCallData.$touch()
      if (!this.$v.backCallData.$error){
        const res = (await this.axios.post('order/back_call', JSON.stringify({
          name : this.backCallData.name,
          phone : this.backCallData.tel,
          message : this.backCallData.message
        }) ))
        if (res.status === 200){
          this.backCallData = {
            name: '',
            tel: '',
            message: ''
          }
          this.$v.backCallData.$reset()
          this.$modal.hide('back-call')
          this.$fire({
            position: 'top',
            text: "Обратный звонок заказать",
            type: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    }
  }
}
</script>

<style scoped>

</style>