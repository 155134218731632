<template>
  <modal
      name="city"
      class="city"
      :width="'auto'"
      :height="'auto'"
  >
    <div class="form-l">
      <img
          src="@/assets/images/close-popup.png"
          class="form-l__close"
          @click="$modal.hide('city')"
      >
      <h1 class="h1-page">Выберите город</h1>
      <div class="city-wrapper">
        <div
            class="city-item"
            v-for="item in regions"
            :key="item.id"
        >
          <button v-if="item.city == regionName" style="color: #540b7c">{{ item.city }}</button>
          <button v-else
                  class="city_name_con"
                  @click="getDomain(item.domain)">
            {{ item.city }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "City",
  props: ['regionName'],

  data() {
    return {
      regions: null
    }
  },

  methods: {
    ...mapActions(['getRegionLists']),

    getDomain(item) {
      window.location.href = this.$https ? "https://" + item : "http://" + item
    }
  },

  mounted() {
    this.getRegionLists().then((res) => {
      this.regions = res
    })
  }
}
</script>

<style lang="scss">
.city {
  .form-l {
    max-width: 800px;
    width: 100%;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }

  .city-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .city-item {
    max-width: 25%;
    width: 100%;
    margin-top: 15px;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        transition: 0.8ms all;
        text-decoration: underline;
      }
    }
  }
  .city_name_con:hover {
    color: #540b7c
  }
}
</style>
