<template>
  <div>
    <MenuVertical
        :sub1="order.category"
        :category_id="order.category_id"
        :sub2="order.title"
    />
    <Spiner v-show="isLoading"/>
    <div :class="isLoading ? 'd__none' : ''">
      <section class="product">
        <div class="container">
          <div class="product__wrapper w-product">
            <div class="w-product__image" style="max-width:460px;">
              <div class="swiper mySwiper2">
                <div class="swiper-wrapper">
                  <div
                      class="swiper-slide"
                      v-for="(img, idx) in order.image"
                      :key="idx"
                  >
                    <img :src="img"/>
                  </div>
                  <div
                      class="swiper-slide"
                      v-if="!order.image"
                  >
                    <img :src="require('@/assets/images/no-img.jpg')"/>
                  </div>
                </div>
              </div>
              <div class="swiper mySwiper">
                <div class="swiper-wrapper">
                  <div
                      class="swiper-slide"
                      v-for="(img, idx) in order.image"
                      :key="idx"
                  >
                    <img :src="img"/>
                  </div>
                  <div
                      class="swiper-slide"
                      v-if="!order.image"
                  >
                    <img :src="require('@/assets/images/no-img.jpg')"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-product__info p-info">
              <div class="p-info__flex">
                <div class="p-info__compare">
                  <router-link :to="{path: `/compare/${order.id}`}">
                    <img src="@/assets/images/icon-product-compare.png">
                    <span>Добавить к сравнению</span>
                  </router-link>
                </div>
                <div class="p-info__share">
                  <button @click="shareShow = !shareShow">
                    <img src="@/assets/images/icon-product-share.png">
                    <span>Поделиться</span>
                  </button>
                  <div
                      class="h-link-order__form w-add-sale"
                      id="w-add-sale"
                      v-if="shareShow"
                  >
                    <div>
                      <a :href="`https://vk.com/share.php?url=http://45.141.78.32:8200/#/${this.$route.path}`">
                        <img src="@/assets/images/vk.png">
                        <span>ВКонтакте</span>
                      </a>
                    </div>
                    <div>
                      <a
                          :href="`https://xn--r1a.link/share/url?url=http%3A%2F%2F45.141.78.32%3A8200%2F%23%2Fproduct%2F${$route.params.orderId}`">
                        <img src="@/assets/images/tg.png">
                        <span>Telegram</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="p-info__comercial">
                  <a href="#">
                    <img src="@/assets/images/icon-product-commercial.png">
                    <span>Коммерческое предложение</span>
                  </a>
                </div>
              </div>
              <div class="w-product__title">
                <h1>{{ order.title }}</h1>
              </div>
              <div class="w-product__article">
                <span>Артикул: {{ order.sku }}</span>
              </div>
              <div class="w-product__manufacture">
                <span>Производитель: <button @click="getBrandId(order.brand)">{{ order.brand }}</button></span>
              </div>
              <div class="w-product__price w-product-price">
                <div class="w-product-price__text">Цена:</div>
                <div class="w-product-price__caption">{{ order.custom_price }} руб.</div>
                <div class="cart-product__price--old" v-if="order.price !== order.old_price">{{
                    order.custom_old_price
                  }} руб.
                </div>
              </div>
              <div class="w-product__btn w-product-btn">
                <button type="submit" class="add-basket"
                        v-if="order.in_stock > 0"
                        @click="$router.push(`/checkout/${$route.params.orderId}`)"
                >В корзину
                </button>
                <button
                    type="button"
                    v-if="order.in_stock <= 0"
                    class="no-product"
                >Нет в наличии
                </button>
                <button
                    type="submit"
                    class="add-sale"
                    id="w-add-sale-button"
                    @click="showSale"
                >Получить скидку
                </button>

                <div
                    class="h-link-order__form w-add-sale"
                    id="w-add-sale"
                    v-if="saleShow"
                >
                  <form>
                    <input
                        type="text"
                        class="w-add-sale__input"
                        placeholder="Ваш телефон"
                        v-model="saleData.tel"
                        :class="{'w-add-sale__input-error' : $v.saleData.tel.$dirty && $v.saleData.tel.$error}"
                    >
                    <input
                        type="text"
                        class="w-add-sale__input"
                        placeholder="Ваш e-mail"
                        v-model="saleData.email"
                        :class="{'w-add-sale__input-error' : $v.saleData.email.$dirty && $v.saleData.email.$error}"
                    >
                    <button
                        class="w-add-sale__btn"
                        @click="getSaleData(order.id)"
                        :class="{'disabled-button': disabledButton}"
                        :disabled="disabledButton"
                    >Получить
                    </button>
                  </form>
                </div>
              </div>
              <div
                  class="w-product__linker"
                  v-if="order.leasing"
              >
                Товар доступен для приобретения в
                <a href="#">лизинг</a> или <a href="#">кредит</a></div>
              <div
                  @click="getDownloadFile(idx)"
                  style="cursor: pointer"
                  v-for="(doc, idx) in order.document"
                  :key="idx"
              >
                <a
                    class="w-product__file product-file">
                  <img src="@/assets/images/icon-pdf.png">
                  <div>
                    <div class="product-file__download">Скачать инструкцию</div>
                    <div class="product-file__weight">(PDF, {{ getDocumentSize(idx) }} Кб)</div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="product__description w-description">
            <div class="w-description__list w-description-l">
              <div
                  class="w-description-l__item"
                  :class="{'active' : activeDescription === idx}"
                  v-for="(descr, idx) in description"
                  :key="idx"
                  @click="activeDescription = idx"
              >{{ descr.title }}
              </div>
            </div>
            <div class="w-description__caption w-description-c">
              <div class="w-description-c__item active"
                   v-if="description[activeDescription].key !== 'characteristics'"
                   v-html="order[description[activeDescription].key]"
              >
                <p>{{ description[activeDescription].key }}</p>
              </div>
              <div
                  v-else
              >
                <section class="table-compare">
                    <div class="table-compare__wrap t-compare ">
                      <div class="t-compare__tr"
                           v-for="(item, i) in order.characteristics"
                           :key="i"
                      >
                        <div class="t-compare__td">{{ item.attribute.title }}</div>
                        <div class="t-compare__td">{{ item.value }}</div>
                      </div>
                    </div>
                </section>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="new">
        <div class="container">
          <div class="title-wrapper">
            <div class="title-wrapper__title">С этим товаром покупают</div>
            <div class="title-wrapper__view t-view">
              <span class="t-view__text">Показать:</span>
              <span class="t-view__grid" @click="activeGrid = false">
                  <img v-if="!activeGrid" src="@/assets/images/view-grid.png">
                  <img v-if="activeGrid" src="@/assets/images/view-grid-noactive.png">
                </span>
              <span class="t-view__list" @click="activeGrid = true">
                  <img v-if="!activeGrid" src="@/assets/images/view-list.png">
                  <img v-if="activeGrid" src="@/assets/images/view-list-active.png">
                </span>
            </div>
          </div>
          <div
              v-if="!activeGrid"
              class="new__products n-products  page-products-grid">

            <div
                class="cart-product n-products__item"
                v-for="(product,idx) in getAnalogOrders()"
                :key="idx"
                @click="getProduct(product.id)"
            >
              <div class="cart-product__img">
                <img :src="product.image ? product.image : require('@/assets/images/no-img.jpg')">
              </div>
              <div class="cart-product__text">{{ product.title }}</div>
              <div class="wrap-price">
                <div
                    v-if="product.old_price !== product.price"
                >
                  <div class="cart-product__price cart-product__price--new">{{ product.price }} руб.</div>
                  <div class="cart-product__price cart-product__price--old">{{ product.old_price }} руб.</div>
                </div>
                <div
                    v-if="product.old_price === product.price"
                    class="cart-product__price"
                >{{ product.price }} руб.
                </div>
              </div>
              <div class="cart-product__btn">
                <button
                    class="btn-main"
                    v-if="product.in_stock > 0"
                    @click.stop="$router.push(`/checkout/${product.id}`)"
                >В корзину
                </button>
                <button
                    type="button"
                    v-if="product.in_stock <= 0"
                    class="no-product"
                >Нет в наличии
                </button>
                <!--              <router-link :to="{path: `/checkout/${product.id}`}" class="btn-main">В корзину</router-link>-->
                <router-link :to="{path: '/payment_shipping'}" class="btn-secondary">Подробнее</router-link>
              </div>
              <div class="cart-product__compare">
              <span>
                <img src="@/assets/images/add-compare.svg">
              </span>
                <button @click.stop="$router.push(`/compare/${product.id}`)">Добавить к сравнению</button>
              </div>
            </div>
          </div>

          <div
              v-if="activeGrid"
              class="new__products n-products  page-products page-products-list">
            <div
                class="cart-product n-products__item n-products__item--list"
                v-for="(product,idx) in getAnalogOrders()"
                :key="idx"
                @click="getProduct(product.id)"
            >
              <div class="flex-helper">
                <div class="cart-product__img">
                  <img :src="product.image ? product.image : require('@/assets/images/no-img.jpg')">
                </div>
                <div>
                  <div class="cart-product__text">{{ product.title }}</div>
                  <div class="cart-product__compare">
                  <span>
                    <img src="@/assets/images/add-compare.svg">
                  </span>
                    <button @click.stop="$router.push(`/compare/${product.id}`)">Добавить к сравнению</button>
                  </div>
                </div>
              </div>
              <div>
                <div class="wrap-price">
                  <div
                      v-if="product.old_price !== product.price"
                  >
                    <div class="cart-product__price cart-product__price--new">{{ product.price }} руб.</div>
                    <div class="cart-product__price cart-product__price--old">{{ product.old_price }} руб.</div>
                  </div>
                  <div
                      v-if="product.old_price === product.price"
                      class="cart-product__price"
                  >{{ product.price }} руб.
                  </div>
                </div>
                <div class="cart-product__btn">
                  <button
                      class="btn-main"
                      v-if="product.in_stock > 0"
                      @click.stop="$router.push(`/checkout/${product.id}`)"
                  >В корзину
                  </button>
                  <button
                      type="button"
                      v-if="product.in_stock <= 0"
                      class="no-product"
                  >Нет в наличии
                  </button>
                  <router-link :to="{path: '/payment_shipping'}" class="btn-secondary">Подробнее</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Swiper from "@/assets/js/swiper"
import 'swiper/swiper-bundle.css'
import '@/assets/js/jquery.min.js'
import MenuVertical from "@/components/menu/MenuVertical";
import {validationMixin} from 'vuelidate'
import {required, email, numeric} from 'vuelidate/lib/validators'
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import Spiner from "@/components/global-component/Spiner";
/* eslint-disable */
export default {
  name: "Product",
  mixins: [validationMixin],
  components: {
    MenuVertical,
    Spiner
  },
  data() {
    return {
      isLoading: true,
      id: 1,
      order: [],
      activeDescription: 0,
      activeGrid: false,
      saleShow: false,
      analogOrders: [],
      disabledButton: false,
      saleData: {
        tel: '',
        email: ''
      },
      description: [
        {
          key: 'description',
          title: 'Описание'
        },
        {
          key: 'characteristics',
          title: 'Характеристики'
        },
        {
          key: 'spare_parts',
          title: 'Видео'
        },
        {
          key: 'accessories',
          title: 'Комплектация'
        }
      ],
      shareShow: false,
      orderId: null,
    }
  },
  validations: {
    saleData: {
      tel: {
        required,
        numeric
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    ...mapActions(['productIdRemove']),
    showSale() {
      this.$v.saleData.$reset()
      this.saleShow = !this.saleShow
    },
    async getSaleData(orderId) {
      this.$v.saleData.$touch()
      if (!this.$v.saleData.$error) {
        this.disabledButton = true
        const res = await this.axios.post('/product/sale', JSON.stringify({
          "product_id": orderId,
          "sale_email": this.saleData.email,
          "sale_phone": this.saleData.tel
        }))
        if (res.status === 200) {
          let message = 'Заявка на скидку отправлена!'
          let type = 'success'
          this.disabledButtonTwo = false
          this.$fire({
            position: 'top',
            text: message,
            type: type,
            timer: 3000,
            showConfirmButton: false,
          });
          this.saleShow = false
          this.saleData = {
            tel: '',
            email: ''
          }
        }
      }
    },
    getDocumentSize(idx) {
      return this.order.document[idx].size
    },
    getDownloadFile(idx) {
      const url = this.order.document[idx].src
      const urlLastPart = this.order.document[idx].src.split("/").pop()
      let req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "blob";
      req.onload = function () {
        let blob = new Blob([req.response], {type: "application/octetstream"});

        let isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(blob, urlLastPart);
        } else {
          let url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          let a = document.createElement("a");
          a.setAttribute("download", urlLastPart);
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      req.send();
    },
    async getProduct(id) {
      this.$router.push(`/product/${id}`)
      this.productIdRemove(id)
      window.scrollTo(0, 0)
    },
    getBrandId(title) {
      if (this.brandsData) {
        let id = null
        this.brandsData.forEach((brand) => {
          if (brand.title === title) {
            id = brand.id
          }
        })
        this.$router.push(`/brand/${id}`)
      }
    },
    getAnalogOrders() {
      if (this.analogOrders) {
        return this.analogOrders.slice(0, 4)
      }
      return []
    }
  },
  computed: {
    ...mapState(['brandsData', 'productId']),
  },
  async created() {
    window.scrollTo(0, 0)
  },
  async mounted() {
    window.scrollTo(0, 0)
    // this.order = (await this.axios.get(`/product/${this.$route.params.orderId}`)).data || []
    this.axios.get(`/product/${this.$route.params.orderId}`)
        .then(response => {
          this.order = response.data
          this.analogOrders = response.data.analog_products
          this.axios.post('/product/popular_product', JSON.stringify({product_id: this.$route.params.orderId}))
          const swiperProduct = new Swiper(".mySwiper", {
            loop: false,
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
          });
          new Swiper(".mySwiper2", {
            loop: false,
            spaceBetween: 10,
            freeMode: true,
            thumbs: {
              swiper: swiperProduct,
            },
          });
          this.isLoading = false
        });
    if (this.order) {
      await this.axios.post('/product/popular_product', JSON.stringify({product_id: this.$route.params.orderId}))
    }
  },
  watch: {
    productId: async function () {
      window.location.reload()
    }
  }
}
</script>

<style>
.table-compare .t-compare {
  margin-top: 0;
}


.page-products-grid {
  grid-template-columns: repeat(4, 1fr);
}

.add-sale-show {
  opacity: 1;
}

.disabled-button {
  opacity: .3 !important;
}

.d__none {
  display: none !important;
}

@media screen and (max-width: 1280px) {
  .page-products-grid {
    grid-template-columns: 1fr;
  }

  .cart-product {
    margin: auto;
  }
}

.style_col_g {
  color: red;
}

/*span, p {*/
/*  font-size: 15px!important;*/
/*}*/
</style>
