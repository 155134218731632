<template>
  <div>
    <div class="loader">
      <scale-loader :color="color" :height="height" :width="width"></scale-loader>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spiner",
  data () {
    return{
      color: '#076f33',
      width: '7px',
      height: '45px',
    }
  }
}
</script>

<style>
.loader{
  width: 100%;
  padding: 100px 0;
}
</style>