<template>
  <div class="header__menu menu-mob" v-if="mobileMenu">
    <ul class="menu-mob__list">
      <li class="menu-item__item" @click="getMobileMenu">
        <router-link :to="{path: '/about-company'}" class="menu-mob__link">О компании</router-link>
      </li>
      <li class="menu-item__item" @click="getMobileMenu">
        <router-link :to="{path: '/service'}" class="menu-mob__link">Сервис</router-link>
      </li>
      <li class="menu-item__item" @click="getMobileMenu">
        <router-link :to="{path: '/payment_shipping'}" class="menu-mob__link">Доставка и оплата</router-link>
      </li>
      <li class="menu-item__item" @click="getMobileMenu">
        <router-link :to="{path: '/leasing'}" class="menu-mob__link">Лизинг</router-link>
      </li>
      <li class="menu-item__item" @click="getMobileMenu">
        <router-link :to="{path: '/contact'}" class="menu-mob__link">Контакты</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "MenuMob",
  computed: {
    ...mapState(['mobileMenu'])
  },
  methods:{
    ...mapActions(['mobileMenuRemove']),
    getMobileMenu () {
      this.mobileMenuRemove(false)
    }
  }
}
</script>

<style scoped>

</style>