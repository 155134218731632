<template>
<div>
  <MenuVertical
      sub1="Оформление заказа"
  />
  <section class="checkout">
    <div class="container">
      <div class="title-block">
        <span>Введите данные</span>
      </div>
      <div class="checkout__wrapper w-checkout">
        <div class="w-checkout__contain">
          <div class="input-default-wrap">
            <input
                type="text"
                name="fio"
                placeholder="ФИО *"
                class="input-default"
                autocomplete="off"
                v-model="formData.fio"
                :class="{'input-default-wrap-error' : $v.formData.fio.$dirty && $v.formData.fio.$error}"
            >
          </div>
          <div class="input-default-wrap">
            <input
                type="text"
                name="phone"
                placeholder="Телефон *"
                class="input-default"
                autocomplete="off"
                v-model="formData.phone"
                :class="{'input-default-wrap-error' : $v.formData.phone.$dirty && $v.formData.phone.$error}"
            >
          </div>
          <div class="input-default-wrap">
            <input type="text"
                   name="email"
                   placeholder="E-mail *"
                   class="input-default"
                   autocomplete="off"
                   v-model="formData.email"
                   :class="{'input-default-wrap-error' : $v.formData.email.$dirty && $v.formData.email.$error}"
            >
          </div>
          <div class="input-default-wrap">
            <input
                type="text"
                name="company"
                placeholder="Компания (необязательно)"
                class="input-default"
                autocomplete="off"
                v-model="formData.company"
            >
          </div>
          <div class="input-default-wrap">
            <input
                type="text"
                name="comment"
                placeholder="Комментарий (необязательно)"
                class="input-default"
                autocomplete="off"
                v-model="formData.comment"
            >
          </div>

          <div class="input-default-wrap input-default-wrap--center cheker-style">
            <input
                id="term-input"
                type="checkbox"
                name="term"
                class="default-checkbox"
                v-model="formCheck"
                @change="getFormData"
            >
            <label for="term-input">Я согласен на обработку <a href="#">персональных данных</a><span>*</span></label>
          </div>

          <div
              class="basket"
              v-if="userIdData.length"
          >
            <div
                class="basket__product b-product"
                v-for="(product, idx) in userIdData"
                :key="idx"
                @click.stop="$router.push(`/product/${product.id}`)"
            >
              <div class="b-product__img">
                  <img :src="product.image ? product.image[0] : require('@/assets/images/no-img.jpg')">
              </div>
              <div class="b-product__name">
                  {{ product.title }}
              </div>
              <div class="b-product__price b-product-price">
                <div class="b-product-price__num">{{Math.round(product.price) / 1}} P</div>
                <div class="b-product-price__text">цена за 1 шт</div>
              </div>
              <div class="b-product__quanty">
                <div
                    class="b-product__qtyMinus"
                    @click.stop="productCountMinus(product, idx)"
                >-</div>
                <div class="b-product__quantyW">
                  <input type="text" name="quanty" class="b-product__inputQty" v-model="product.count" disabled="disabled">
                  <div class="b-product__quantyW1">шт</div>
                </div>
                <div class="b-product__qtyPlus"
                     @click.stop="productCountPlus(product, idx)"
                >+</div>
              </div>
              <div class="b-product__total b-product-total">
                <div class="b-product-total__num">{{Math.round(product.price * product.count)}} P</div>
                <div class="b-product-total__text">итого</div>
              </div>
              <div
                  class="b-product__close"
                  @click.stop="deleteCheckOutOrder(idx)"
              >
                <img src="@/assets/images/icon-close.png">
              </div>
            </div>
          </div>
          <button @click.stop="getUpdate" class="refresh-btn">Обновить корзину</button>
        </div>
        <div class="w-checkout__total t-checkout">
          <div class="t-checkout__head">Ваш заказ</div>
          <div class="t-checkout__flex">
            <div class="t-checkout__text">Всего товаров: </div>
            <div class="t-checkout__count">{{ userIdData.length }} шт.</div>
          </div>
          <div class="t-checkout__total">Итоговая сумма:</div>
          <div class="t-checkout__price">{{ Math.round(totalPrice) }} Р</div>
          <div class="t-checkout__column">
            <div class="t-checkout__checkbox"  @click="getType('liz')">
              <input type="checkbox" id="liz" name="liz" ref="liz">
              <label for="liz">Купить в лизинг</label>
            </div>
            <div class="t-checkout__checkbox" @click="getType('credit')">
              <input type="checkbox" id="credit" name="credit" ref="credit">
              <label for="credit">Купить в кредит</label>
            </div>
          </div>
          <div class="checker">
            <button
                class="t-checkout__submit"
                type="submit"
                @click="checkoutOrder"
                :class="{'disabled-button': disabledButton}"
                :disabled="disabledButton"
            >Подтвердить заказ</button>
            <button
                class="t-checkout__sale add-sale"
                type="submit"
                @click="saleShow = !saleShow"
            >Получить скидку</button>

            <div
                class="h-link-order__form w-add-sale"
                v-if="saleShow"
            >
              <form>
                <input
                    type="text"
                    class="w-add-sale__input"
                    placeholder="Ваш телефон"
                    v-model="saleData.tel"
                    :class="{'input-default-wrap-error' : $v.saleData.tel.$dirty && $v.saleData.tel.$error}"
                >
                <input
                    type="text"
                    autocomplete="off"
                    name="email"
                    class="w-add-sale__input"
                    placeholder="Ваш e-mail"
                    v-model="saleData.email"
                    :class="{'input-default-wrap-error' : $v.saleData.email.$dirty && $v.saleData.email.$error}"
                >
                <button
                    class="w-add-sale__btn"
                    type="button"
                    @click="getSaleData"
                    :class="{'disabled-button': disabledButtonTwo}"
                    :disabled="disabledButtonTwo"
                >Получить</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import MenuVertical from "@/components/menu/MenuVertical";
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import { validationMixin } from 'vuelidate'
import { required, email, helpers } from 'vuelidate/lib/validators'
const phoneNumber = helpers.regex('phoneNumber', /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/)
export default {
  name: "Checkout",
  mixins: [validationMixin],
  components: {MenuVertical},
  data() {
    return {
      order: null,
      checkOutData: [],
      orders: [],
      cardProducts: [],
      disabledButton: false,
      disabledButtonTwo: false,
      formData: {
        fio: '',
        phone: '',
        email: '',
        company: '',
        comment: '',
      },
      formCheck: false,
      type: null,
      saleShow: false,
      saleData: {
        tel: '',
        email: ''
      }
    }
  },
  validations: {
    formData: {
      fio: {
        required
      },
      phone: {
        required,
        phoneNumber
      },
      email: {
        required,
        email
      }
    },
    saleData: {
      tel: {
        required,
        phoneNumber
      },
      email: {
        required,
        email
      }
    }
  },
  watch: {
    userIdData: async function () {
      this.cardProducts = []
      if (this.userIdData){
        this.userIdData.forEach((item) => {
          this.cardProducts.push({
            id: item.id,
            count: item.count
          })
        })
      }
      await this.axios.post('/card/add', JSON.stringify({
        "userId": this.userId,
        "cardProduct": this.cardProducts,
        "compareProduct": null
      }))
    },
  },
  async created() {
    window.scrollTo(0, 0)
    try {
      const response = await this.axios.post('/card/get', JSON.stringify({id: this.userId}));
      if (response.status === 200) {
        this.getTotalPrice(); // Пересчитываем итоговую сумму
      }
    } catch (error) {
      console.error('Ошибка при получении состава корзины:', error);
    }
  },
  methods: {
    ...mapActions(['userIdDataRemove', 'userTotalPriceRemove']),
    async deleteCheckOutOrder(idx) {
      let orders = this.userIdData
      orders.splice(idx, 1)
      this.userIdDataRemove(orders)

    },
    productCountMinus(product, idx) {
      product.count = product.count <= 1 ? 1 : Number(product.count) - 1
      this.cardProducts[idx].count = product.count
      this.getTotalPrice()
    },
    productCountPlus(product, idx) {
      product.count = Number(product.count) >= Number(product.in_stock) ? Number(product.in_stock) : Number(product.count) + 1
      this.cardProducts[idx].count = product.count
      this.getTotalPrice()
    },
    getFormData() {
      if (this.formCheck) {
        this.$v.formData.$touch()
      }
    },
    getType(type) {
      this.type = type
      if (this.type === 'liz' && !this.$refs.liz.checked) {
        this.$refs.credit.checked = false
        this.$refs.liz.checked = true
      } else {
        this.$refs.liz.checked = false
      }
      if (this.type === 'credit' && !this.$refs.credit.checked) {
        this.$refs.liz.checked = false
        this.$refs.credit.checked = true
      } else {
        this.$refs.credit.checked = false
      }
    },
    async checkoutOrder() {
      this.$v.formData.$touch()
      if (!this.$v.formData.$error && this.formCheck) {
        this.disabledButton = true
        const res = await this.axios.post('/order/store', JSON.stringify({
          user_id: this.userId,
          fio: this.formData.fio,
          phone: this.formData.phone,
          email: this.formData.email,
          company: this.formData.company,
          comment: this.formData.comment,
          type: this.type,
          products: this.cardProducts
        }))
        if (res.status === 200) {
          let message = 'Заказ подтвержден'
          let type = 'success'
          if (!this.cardProducts.length){
            message = 'Корзина пуста'
            type = 'warning'
          }
          this.disabledButton = false
          this.$fire({
            position: 'top',
            text: message,
            type: type,
            timer: 3000,
            showConfirmButton: false,
          });
          this.formData = {
            fio: '',
            phone: '',
            email: '',
            company: '',
            comment: '',
          }
          this.formCheck = false
          this.type = null
          this.$v.formData.$reset()
          this.cardProducts = []
          this.userIdDataRemove([])
        }
      }else{
        let message = 'Заполните все поля'
        this.$fire({
          position: 'top',
          text: message,
          type: "warning",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    async getDiscount () {
      this.$v.formData.$touch()
      if (!this.$v.formData.$error && this.formCheck) {
        const res = await this.axios.post('/order/store', JSON.stringify({
          user_id: this.userId,
          fio: this.formData.fio,
          phone: this.formData.phone,
          email: this.formData.email,
          company: this.formData.company,
          comment: this.formData.comment,
          type: this.type,
          products: this.cardProducts,
          "sale_email": this.saleData.email,
          "sale_phone": this.saleData.tel
        }))
        if (res.status === 200) {
          let message = 'Заказ подтвержден'
          let type = 'success'
          if (!this.cardProducts.length){
            type = 'warning'
            message = 'Корзина пуста'
          }
          this.$fire({
            position: 'top',
            text: message,
            type: type,
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }else{
        let message = 'Заполните все поля'
        this.$fire({
          position: 'top',
          text: message,
          type: "warning",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    async getSaleData() {
      this.$v.saleData.$touch()
      if (!this.$v.saleData.$error) {
        this.disabledButtonTwo = true
        const res = await this.axios.post('/order/store', JSON.stringify({
          user_id: this.userId,
          fio: this.formData.fio,
          phone: this.formData.phone,
          email: this.formData.email,
          company: this.formData.company,
          comment: this.formData.comment,
          type: this.type,
          products: this.cardProducts,
          "sale_email": this.saleData.email,
          "sale_phone": this.saleData.tel
        }))
        if (res.status === 200) {
          let message = 'Заявка на скидку отправлена'
          let type = 'success'
          this.disabledButtonTwo = false
          if (!this.cardProducts.length) {
            message = 'Корзина пуста'
            type = 'warning'
          }
          this.$fire({
            position: 'top',
            text: message,
            type: type,
            timer: 3000,
            showConfirmButton: false,
          });
        }
        this.saleShow = false
        this.$v.saleData.$reset()
        this.saleData = {
          tel: '',
          email: ''
        }
      } else{
        let message = 'Заполните все поля'
        this.$fire({
          position: 'top',
          text: message,
          type: "warning",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    getTotalPrice() {
      let total = 0
      if (this.userIdData){
        this.userIdData.forEach((val) => {
          total += val.count * val.price
        })
        this.userTotalPriceRemove(total)
      }
    },
    getUpdate () {
      window.location.reload()
    }
  },
  computed: {
    ...mapState(['userId', 'userIdData', 'totalPrice']),
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
.disabled-button{
  opacity: .3 !important;
}

</style>
