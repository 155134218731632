<template>
<div class="page-404">
  <img src="../assets/images/404.jpg" alt="404">
</div>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style lang="scss">
.page-404{
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>