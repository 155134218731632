<template>
  <div>
    <Spiner
      v-if="!aboutData"
    />
    <SubPage
        v-if="aboutData"
        :subPageData="aboutData"
    />
  </div>
</template>

<script>
import SubPage from "@/components/global-component/SubPage"
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import Spiner from "@/components/global-component/Spiner";

export default {
  name: "AboutCompany",
  components: {
    Spiner,
    SubPage
  },

  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['aboutDataRemove']),
  },
  computed: {
    ...mapState(['aboutData'])

  },
  async mounted() {
    window.scrollTo(0, 0)
    this.aboutDataRemove()
  }
}
</script>

<style lang="scss">

</style>
