<template>
  <section class="map">
      <div class="text-default map__info"
           :class="{'show-text': showText}"
      >
        <p>Мы поставляем оборудование для автосервиса с 1998 года в РФ и страны СНГ. Предоставляем гарантийное
          обслуживание до 24 месяцев. У нас самый большой ассортимент оборудования для автосервиса. С нами работают
          крупные дилерские компании по всей России и странах СНГ. Если у вас автосервис или вы являетесь дилером, то
          компания Мастер-Инструмент поможет подобрать нужное оборудование, доставит его быстро, благодаря налаженной
          работе службы доставки.
        </p>
        <p>
          В нашем интернет-магазине больше 12000 товаров для автосервиса. Продаем гаражное оборудование, оборудование
          для грузового автосервиса и мотосервиса, шиномонтажное оборудование, компрессоры, окрасочное оборудование,
          кузовное, диагностическое, подъемное, пневматическое и гидравлическое оборудование.
        </p>
        <p>
          В нашем интернет-магазине больше 12000 товаров для автосервиса. Продаем гаражное оборудование, оборудование
          для грузового автосервиса и мотосервиса, шиномонтажное оборудование, компрессоры, окрасочное оборудование,
          кузовное, диагностическое, подъемное, пневматическое и гидравлическое оборудование.
        </p>
      </div>
    <button
        class="map__btn btn-more"
        @click="showText = !showText"
    >
      {{showText ? 'Скрыть' : 'Подробнее'}}
    </button>
  </section>
</template>

<script>
export default {
  name: "MapInfo",
  data () {
    return{
      showText: false
    }
  }
}
</script>

<style>

</style>