import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import VueSweetalert2 from 'vue-sweetalert2';
// import VueSweetalert2 from 'sweetalert2-neutral';
import 'sweetalert2/dist/sweetalert2.min.css';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueObserveVisibility from 'vue-observe-visibility'



Vue.config.productionTip = false
Vue.prototype.$https = false
axios.defaults.baseURL = 'http://t.aoed.ru/api/';

const service = axios.create({
  baseURL: 'http://t.aoed.ru/api/',
  headers: {
    "content-type": "application/json",
  }
})
// VueSweetalert2 = new VueSweetalert2();
// Vue.use(VueSweetalert2);
Vue.use(VModal)
Vue.use(VueVideoPlayer)
Vue.use(Vuelidate)
Vue.use(VueObserveVisibility)
Vue.use(VueAxios, service)
Vue.use(VueSweetalert2);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('scale-loader', ScaleLoader);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
