<template>
  <div>
    <MenuVertical
        sub1="Каталог"
        :sub2="product.title"
    />
    <section class="catalog">
      <div class="container">
        <div class="catalog__wrapper w-catalog">
          <div class="w-catalog__slidebar w-slidebar">
            <div class="w-slidebar__filter s-filter" v-show="showProducts" v-if="dataLoaded">
              <div class="s-filter__name">Цена (руб.)</div>
              <div class="s-filter__label">
                <div
                    id="slider-range"
                    class="s-filter__range"
                >
                  <vue-slider ref="slider1"
                        v-model="rangeValue"
                              v-bind="rangeOption"
                  ></vue-slider>
                </div>
                <div class="s-filter__labels">
                  <span>от</span>
                  <input class="banner-filter__min" v-model="rangeOption.min">
                  <span>до</span>
                  <input class="banner-filter__max" v-model="rangeOption.max">
                </div>
              </div>
              <div class="s-filter__name">Бренд (производитель)</div>
              <div class="s-filter__chechker s-filter-check">
                <div
                    class="s-filter-check__item"
                    v-for="(brand, idx) in activeBrands"
                    :key="idx"
                >
                  <input :id="brand.brandId"
                         type="checkbox"
                         name="name"
                         class="s-filter-check__chechbox"
                         v-model="brand.brandChack"
                  >
                  <label :for="brand.brandId">{{ brand.brandTitle }}</label>
                </div>
              </div>
              <div class="s-filter__btns">
                <button
                    class="s-filter__btn btn-main"
                    @click="getFilterData"
                >Подобрать
                </button>
                <button
                    class="s-filter__refresh"
                    @click="resetFilterData()"
                >Сброс
                </button>
              </div>
            </div>
            <nav v-if="subCategoryProduct">
              <ul class="w-slidebar__list s-list">
                <li
                    class="s-list__item"
                    :class="{'s-list__item-active' : activeCategory === idx}"
                    v-for="(subCategory, idx) in subCategoryProduct"
                    :key="idx"
                    @click="getActiveCategory(idx, subCategory.id)"
                >
                  {{ subCategory.title.replace(/\s*\(\d+\)\s*/g, '') }}
                </li>
              </ul>
            </nav>
          </div>
          <div class="w-catalog__contain">
            <h1 class="h1-page">{{ product.title }}</h1>
            <div v-if="showSubmenu">
              <section class="sub-category" v-if="subCategoryProduct">
              <div class="sub-category__list s-category-list">
                <div
                    class="s-category-list__item"
                    :class="{'s-category-list__item-active' : activeCategory === idx}"
                    v-for="(subCategory, idx) in subCategoryProduct"
                    :key="idx"
                    @click="getActiveCategory(idx , subCategory.id)"
                >
                  <div>
                    <img :src="subCategory.img ? subCategory.img : require('@/assets/images/no-img.jpg')">
                  </div>
                  <span>{{ subCategory.title.replace(/\s*\(\d+\)\s*/g, '') }}</span>
                </div>
              </div>
            </section>
            <div v-else>
              <Spiner v-if="isLoading"/>
              <div v-else>
                <p
                    class="h1-page"
                    style="font-weight: 700; margin-top: 20px; text-align: center"
                >
                  Ничего не найдено
                </p>
                <p
                    style="margin-top: 20px; text-align: center"
                >
                  Извините, в данный момент этот товар недоступен
                </p>
              </div>
            </div>
            </div>

            <section class="popular" v-if="showProducts">
              <div class="title-wrapper">
                <div class="sort-catalog">
                  <div class="sort-catalog__item">Сортировать по:</div>
                  <div
                      class="sort-catalog__item"
                      v-for="(sortTitle, idx) in sortText"
                      :key="idx"
                  >
                    <button
                        @click="getSortId(sortTitle.id, idx, sortTitle, isUpSort)"
                        :class="{'active': activeSortId === idx}"
                    >{{ sortTitle.title }}
                    </button>
                  </div>
                </div>
                <div class="title-wrapper__view t-view">
                  <span class="t-view__text">Показать:</span>
                  <span class="t-view__grid" @click="activeGrid = false">
                  <img v-if="!activeGrid" src="@/assets/images/view-grid.png">
                  <img v-if="activeGrid" src="@/assets/images/view-grid-noactive.png">
                </span>
                  <span class="t-view__list" @click="activeGrid = true">
                  <img v-if="!activeGrid" src="@/assets/images/view-list.png">
                  <img v-if="activeGrid" src="@/assets/images/view-list-active.png">
                </span>
                </div>
              </div>
              <div v-if="!activeGrid"
                   class="new__products n-products page-products page-products-grid">
                <div
                    class="cart-product n-products__item"
                    v-for="(prd, idx) in categoryProducts"
                    :key="idx"
                    @click="getProduct(prd.id)"
                >
                  <div class="cart-product__img">
                    <img :src="prd.img[0] ? prd.img[0] : require('@/assets/images/no-img.jpg')">
                  </div>
                  <div class="cart-product__text">
                    {{ prd.title }}
                  </div>
                  <div class="wrap-price">
                    <div
                        v-if="prd.old_price !== prd.price"
                    >
                      <div class="cart-product__price cart-product__price--new">{{ prd.custom_price }} руб.</div>
                      <div class="cart-product__price cart-product__price--old">{{ prd.custom_old_price }} руб.</div>
                    </div>
                    <div
                        v-if="prd.old_price === prd.price"
                        class="cart-product__price"
                    >{{ prd.custom_price }} руб.
                    </div>
                  </div>
                  <div class="cart-product__btn">
                    <button
                        class="btn-main"
                        v-if="prd.in_stock > 0"
                        @click.stop="getProductCart(prd.id)"
                    >В корзину
                    </button>
                    <button
                        type="button"
                        v-if="prd.in_stock <= 0"
                        class="no-product"
                    >Нет в наличии
                    </button>
                    <button @click.stop="$router.push(`/product/${prd.id}`)" class="btn-secondary">Подробнее</button>
                  </div>
                  <div class="cart-product__compare">
                    <div v-if="compareProductIDs.length && compareProductIDs.includes(prd.id)">
                      <button type="button"
                      >
                        Уже добавлено
                      </button>
                    </div>
                    <div v-else style="display: flex">
                       <span>
                          <img src="@/assets/images/add-compare.svg">
                       </span>
                      <button type="button"
                              @click.stop="getComparePage(prd.id, prd)"
                      >
                        Добавить к сравнению
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeGrid"
                   class="new__products n-products page-products page-products-list">
                <div class="cart-product n-products__item n-products__item--list"
                     v-for="(prd, idx) in categoryProducts"
                     :key="idx"
                     @click="getProduct(prd.id)"
                >
                  <div class="flex-helper">
                    <div class="cart-product__img">
                      <img :src="prd.img[0] ? prd.img[0] : require('@/assets/images/no-img.jpg')">
                    </div>
                    <div>
                      <div class="cart-product__text">
                        {{ prd.title }}
                      </div>
                      <div class="cart-product__compare">
                        <div v-if="compareProductIDs.length && compareProductIDs.includes(prd.id)">
                          <button type="button"
                          >
                            Уже добавлено
                          </button>
                        </div>
                        <div v-else style="display: flex">
                       <span>
                          <img src="@/assets/images/add-compare.svg">
                       </span>
                          <button type="button"
                                  @click.stop="getComparePage(prd.id, prd)"
                          >
                            Добавить к сравнению
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="wrap-price">
                      <div
                          v-if="prd.old_price !== prd.price"
                      >
                        <div class="cart-product__price cart-product__price--new">{{ prd.custom_price }} руб.</div>
                        <div class="cart-product__price cart-product__price--old">{{ prd.custom_old_price }} руб.</div>
                      </div>
                      <div
                          v-if="prd.old_price === prd.price"
                          class="cart-product__price"
                      >{{ prd.custom_price }} руб.
                      </div>
                    </div>
                    <div class="cart-product__btn">
                      <button
                          class="btn-main"
                          v-if="prd.in_stock > 0"
                          @click.stop="getProductCart(prd.id)"
                      >В корзину
                      </button>
                      <button
                          type="button"
                          v-if="prd.in_stock <= 0"
                          class="no-product"
                      >Нет в наличии
                      </button>
                      <button @click.stop="$router.push(`/product/${prd.id}`)" class="btn-secondary">Подробнее</button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!categoryProducts.length">
                <Spiner v-if="isLoading"/>
                <div v-else>
                  <p
                      class="h1-page"
                      style="font-weight: 700; margin-top: 20px; text-align: center"
                  >
                    Ничего не найдено
                  </p>
                  <p
                      style="margin-top: 20px; text-align: center"
                  >
                    Извините, в данный момент этот товар недоступен
                  </p>
                </div>
              </div>

              <!--              <div v-if="categoryProducts" v-observe-visibility="getPage"></div>-->
            </section>

            <section class="popular" v-if="!showProducts">
              <div class="title-wrapper">
                <h2 class="h1-page">Популярные товары</h2>
                <div class="title-wrapper__view t-view">
                  <span class="t-view__text">Показать:</span>
                  <span class="t-view__grid" @click="activeGrid = false">
                  <img v-if="!activeGrid" src="@/assets/images/view-grid.png">
                  <img v-if="activeGrid" src="@/assets/images/view-grid-noactive.png">
                </span>
                  <span class="t-view__list" @click="activeGrid = true">
                  <img v-if="!activeGrid" src="@/assets/images/view-list.png">
                  <img v-if="activeGrid" src="@/assets/images/view-list-active.png">
                </span>
                </div>
              </div>
              <div v-if="!activeGrid"
                   class="new__products n-products page-products page-products-grid">
                <div
                    class="cart-product n-products__item"
                    v-for="(prd, idx) in categoryProducts"
                    :key="idx"
                    @click="getProduct(prd.id)"
                >
                  <div class="cart-product__img">
                    <img :src="prd.img[0] ? prd.img[0] : require('@/assets/images/no-img.jpg')">
                  </div>
                  <div class="cart-product__text">
                    {{ prd.title }}
                  </div>
                  <div class="wrap-price">
                    <div
                        v-if="prd.old_price !== prd.price"
                    >
                      <div class="cart-product__price cart-product__price--new">{{ prd.custom_price }} руб.</div>
                      <div class="cart-product__price cart-product__price--old">{{ prd.custom_old_price }} руб.</div>
                    </div>
                    <div
                        v-if="prd.old_price === prd.price"
                        class="cart-product__price"
                    >{{ prd.custom_price }} руб.
                    </div>
                  </div>
                  <div class="cart-product__btn">
                    <button
                        class="btn-main"
                        v-if="prd.in_stock > 0"
                        @click.stop="getProductCart(prd.id)"
                    >В корзину
                    </button>
                    <button
                        type="button"
                        v-if="prd.in_stock <= 0"
                        class="no-product"
                    >Нет в наличии
                    </button>
                    <button @click.stop="$router.push(`/product/${prd.id}`)" class="btn-secondary">Подробнее</button>
                  </div>
                  <div class="cart-product__compare">
                    <div v-if="compareProductIDs.length && compareProductIDs.includes(prd.id)">
                      <button type="button"
                      >
                        Уже добавлено
                      </button>
                    </div>
                    <div v-else style="display: flex">
                       <span>
                          <img src="@/assets/images/add-compare.svg">
                       </span>
                      <button type="button"
                              @click.stop="getComparePage(prd.id, prd)"
                      >
                        Добавить к сравнению
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeGrid"
                   class="new__products n-products page-products page-products-list">
                <div class="cart-product n-products__item n-products__item--list"
                     v-for="(prd, idx) in categoryProducts"
                     :key="idx"
                     @click="getProduct(prd.id)"
                >
                  <div class="flex-helper">
                    <div class="cart-product__img">
                      <img :src="prd.img[0] ? prd.img[0] : require('@/assets/images/no-img.jpg')">
                    </div>
                    <div>
                      <div class="cart-product__text">
                        {{ prd.title }}
                      </div>
                      <div class="cart-product__compare">
                        <div v-if="compareProductIDs.length && compareProductIDs.includes(prd.id)">
                          <button type="button"
                          >
                            Уже добавлено
                          </button>
                        </div>
                        <div v-else style="display: flex">
                       <span>
                          <img src="@/assets/images/add-compare.svg">
                       </span>
                          <button type="button"
                                  @click.stop="getComparePage(prd.id, prd)"
                          >
                            Добавить к сравнению
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="wrap-price">
                      <div
                          v-if="prd.old_price !== prd.price"
                      >
                        <div class="cart-product__price cart-product__price--new">{{ prd.custom_price }} руб.</div>
                        <div class="cart-product__price cart-product__price--old">{{ prd.custom_old_price }} руб.</div>
                      </div>
                      <div
                          v-if="prd.old_price === prd.price"
                          class="cart-product__price"
                      >{{ prd.custom_price }} руб.
                      </div>
                    </div>
                    <div class="cart-product__btn">
                      <button
                          class="btn-main"
                          v-if="prd.in_stock > 0"
                          @click.stop="getProductCart(prd.id)"
                      >В корзину
                      </button>
                      <button
                          type="button"
                          v-if="prd.in_stock <= 0"
                          class="no-product"
                      >Нет в наличии
                      </button>
                      <button @click.stop="$router.push(`/product/${prd.id}`)" class="btn-secondary">Подробнее</button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!categoryProducts.length">
                <Spiner v-if="isLoading"/>
                <div v-else>
                  <p
                      class="h1-page"
                      style="font-weight: 700; margin-top: 20px; text-align: center"
                  >
                    Ничего не найдено
                  </p>
                  <p
                      style="margin-top: 20px; text-align: center"
                  >
                    Извините, в данный момент этот товар недоступен
                  </p>
                </div>
              </div>
            </section>

            <MapInfo/>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MapInfo from "@/components/global-component/MapInfo";
import MenuVertical from "@/components/menu/MenuVertical";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import Spiner from "@/components/global-component/Spiner";
export default {
  name: "Category",
  components: {
    MenuVertical,
    MapInfo,
    VueSlider,
    Spiner
  },
  data() {
    return {
      dataLoaded: false,
      cat_id: null,
      compareProductIDs: [],
      showProducts: false,
      showSubmenu: true,
      rangeOption: {
        dotSize: 14,
        width: 'auto',
        height: 6,
        contained: false,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 100000,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'active',
        tooltipPlacement: 'top',
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: null,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      isLoading: true,
      rangeValue: [0, 100000],
      activeGrid: false,
      product: [],
      subCategoryProduct: [],
      activeCategory: null,
      categoryProducts: [],
      sortText: [
        {
          title: 'Наличию',
          id: 1
        },
        {
          title: 'Популярности',
          id: 2
        },
        {
          title: 'Цене',
          id: 3
        }

      ],
      activeSortId: null,
      filterData: {
        minPrice: 0,
        maxPrice: 100000,
        activeBrands: [],
        categoryId: this.$route.params.categoryId,
        subCategoryId: null,
        sortId: null
      },
      activeCompareProduct: [],
      page: 1,
      isUpSort: false,
      activeCompare: [],
    }
  },
  async created() {
    this.product = (await this.axios.get(`/category/${this.$route.params.categoryId}?page=${this.page}`)).data || []
    this.subCategoryProduct = this.product.child_categories || []
    this.categoryProducts = this.product.products || []
  },

  watch: {
    async categoryId() {
      this.isLoading = true
      this.subCategoryProduct = null
      this.categoryProducts = []
      this.page = 1
      this.product = (await this.axios.get(`/category/${this.categoryId}?page=${this.page}`)).data || []
      this.subCategoryProduct = this.product.child_categories
      this.categoryProducts = this.product.products || []
      this.resetFilterData()
      this.isUpSort = false
    },
    rangeValue: {
      deep: true,
      handler(newRangeValue) {
        console.log('Range Value Changed:', newRangeValue);
      },
    },
    subCategoryProduct() {
      if (this.subCategoryProduct !== null) {
        this.isLoading = false
      }
    },

    compareProduct() {
      this.compareProduct.map((item) => {
        this.compareProductIDs.push(item.id)
      })
    },

    userIdData: async function () {
      let cardProducts = []
      if (this.userIdData) {
        this.userIdData.forEach((item) => {
          cardProducts.push({
            id: item.id,
            count: item.count
          })
        })
      }
      await this.axios.post('/card/add', JSON.stringify({
        "userId": this.userId,
        "cardProduct": cardProducts,
        "compareProduct": null
      }))
    },

  },
  computed: {
    ...mapState(['categoryId', 'userId', 'compareProduct', 'brandsData', 'activeBrands', 'userIdData'])
  },
  methods: {
    ...mapActions(['orderRemove', 'compareProductRemove', 'addToCard', 'userIdDataRemove']),

    async getActiveCategory(id, subCategoryId) {
      this.cat_id = subCategoryId
      this.categoryProducts = []
      this.isLoading = true
      this.showProducts = true
      this.showSubmenu = false
      await this.axios.get('/category/get-products/' + subCategoryId)
          .then((res) => {
            if (res.data.products.length > 0) {
              this.categoryProducts = res.data.products
              this.categoryProducts.sort((a, b) => a.price - b.price)
              this.rangeValue[0] = res.data.min_price;
              this.rangeValue[1] = res.data.max_price;
              this.rangeOption.min = this.rangeValue[0];
              this.rangeOption.max = this.rangeValue[1];
              this.rangeOption.interval = (this.rangeOption.max - this.rangeOption.min) / 250;
              this.filterData.minPrice = this.rangeOption.min;
              this.filterData.maxPrice = this.rangeOption.max;
              this.dataLoaded = true; // Устанавливаем флаг загрузки данных
              this.isLoading = false
            }
          })
    },
    async getProduct(id) {
      this.$router.push(`/product/${id}`)
    },
    async getProductCart(id) {
      let res = (await this.axios.get('/product/' + id)).data
      if (res) {
        let orderPush = true
        this.userIdData.forEach((item) => {
          if (Number(item.id) === id) {
            orderPush = false
          }
        })
        if (orderPush) {
          let orders = this.userIdData
          orders.push(res)
          this.userIdDataRemove(orders)
        }

        this.$swal.fire({
          position: 'top-center',
          icon: 'success',
          text: 'Успешно добавлен в корзину',
          showConfirmButton: false,
          timer: 1500
        })
      }

    },
    async getFilterData() {
      this.filterData.activeBrands = this.activeBrands
      this.filterData.minPrice = this.rangeValue[0]
      this.filterData.maxPrice = this.rangeValue[1]
      const res = (await this.axios.post('/category/filter', JSON.stringify(this.filterData)))
      this.categoryProducts = res.data
    },

    resetFilterData() {
      this.activeBrands.forEach((brand) => {
        brand.brandChack = false
      })
      this.rangeValue[0] = this.rangeOption.min
      this.rangeValue[1] = this.rangeOption.max
      this.filterData.minPrice = this.rangeOption.min
      this.filterData.maxPrice = this.rangeOption.max
      this.filterData.activeBrands = this.activeBrands
      this.filterData.sortId = null
      this.activeSortId = null
      this.activeCategory = null
      this.filterData.subCategoryId = null
      this.categoryProducts = this.product.products || []
      this.showProducts = false
      this.showSubmenu = true
    },

    async getSortId(id, idx, titleItem, isUpSort) {
      this.filterData.sortId = id
      this.activeSortId = idx
      const res = (await this.axios.post('/category/filter', JSON.stringify(this.filterData)))
      const resData = res.data
      if(titleItem.title === 'Цене') {
        if (isUpSort) {
          this.categoryProducts = resData.sort((a, b) => a.price - b.price)
          this.isUpSort = false
        } else {
          this.categoryProducts = resData.sort((a, b) => b.price - a.price)
          this.isUpSort = true
        }
      } else if (titleItem.title === 'Наличию') {
        if(!isUpSort) {
          this.categoryProducts = resData.sort((a, b) => b.in_stock - a.in_stock)
          this.isUpSort = true
        } else {
          this.categoryProducts = resData.sort((a, b) => a.in_stock - b.in_stock)
          this.isUpSort = false
        } 
      } else if (titleItem.title === 'Популярности') {
        this.categoryProducts = resData
      }
    },

    async getComparePage(id) {
      this.activeCompare = []
      const res = (await this.axios.post('/card/get', JSON.stringify({id: this.userId}))).data
      if (res.compare_products) {
        res.compare_products.forEach((val) => {
          this.activeCompare.push({
            id: val.id
          })
        })
      }

      let compareAdd = true
      this.activeCompare.forEach((val) => {
        if (val.id === id) {
          compareAdd = false
        }
      })
      if (compareAdd) {
        this.activeCompare.unshift({id})
      }

      let response = await this.axios.post('/card/add', JSON.stringify({
        "userId": this.userId,
        "cardProduct": null,
        "compareProduct": this.activeCompare
      }))

      if (response.data.Message == 'success') {
        const res1 = (await this.axios.post('/card/get', JSON.stringify({id: this.userId}))).data
        this.compareProductRemove(res1.compare_products)
      }

      this.$swal.fire({
        position: 'top-center',
        icon: 'success',
        text: 'Успешно добавлено в сравнение',
        showConfirmButton: false,
        timer: 1500
      })

    },
    // async getPage(isVisible) {
    //   if (this.cat_id) {
    //     if (!isVisible) return
    //     if (this.page >= this.product.pagination.last_page) return
    //     this.page++
    //     this.product = (await this.axios.get(`/category/${this.cat_id}?page=${this.page}`)).data || []
    //     this.categoryProducts.push(...this.product.products)
    //   }
    // },

  },
  mounted() {
    if (!this.compareProductIDs.length && this.compareProduct && this.compareProduct.length) {
      this.compareProduct.map((item) => {
        this.compareProductIDs.push(item.id)
      })
    }
  }
}
</script>

<style>
.vue-slider-rail {
  background-color: #fff;
  border: 1px solid #c5c5c5;
}

.vue-slider-process {
  background: #f60006
}

.vue-slider-dot-handle {
  background: #bababa;
  border-color: #bababa;
}

.vue-slider:hover .vue-slider-dot-handle:hover {
  border-color: #bababa;
}

.vue-slider {
  padding: 0 !important;
  height: 8px !important;
}

.vue-slider:hover .vue-slider-process {
  background-color: #f60006;
}

.t-view__grid {
  margin-right: 5px;
  margin-left: 5px;
}
</style>

