<template>
  <div>
    <MenuVertical
        sub2="Поиск"
    />
    <section class="catalog">
      <div class="container">
        <div class="catalog__wrapper w-catalog">
          <div class="w-catalog__slidebar w-slidebar">
            <div class="w-slidebar__filter s-filter">
              <div class="s-filter__name">Цена (руб.)</div>
              <div class="s-filter__label">
                <div
                    id="slider-range"
                    class="s-filter__range"
                >
                  <vue-slider
                      v-model="rangeValue"
                      v-bind="rangeOption"
                  ></vue-slider>
                </div>
                <div class="s-filter__labels">
                  <span>от</span>
                  <input class="banner-filter__min" v-model="rangeValue[0]">
                  <span>до</span>
                  <input class="banner-filter__max" v-model="rangeValue[1]">
                </div>
              </div>
              <div class="s-filter__name">Бренд (производитель)</div>
              <div class="s-filter__chechker s-filter-check">
                <div
                    class="s-filter-check__item"
                    v-for="(brand, idx) in getActiveBrands()"
                    :key="idx"
                >
                  <input :id="brand.brandId"
                         type="checkbox"
                         name="name"
                         class="s-filter-check__chechbox"
                         v-model="brand.brandChack"
                  >
                  <label :for="brand.brandId">{{brand.brandTitle}}</label>
                </div>
              </div>
              <div class="s-filter__btns">
                <button
                    class="s-filter__btn btn-main"
                    @click="getFilterData"
                >Подобрать</button>
                <button
                    class="s-filter__refresh"
                    @click="resetFilterData"
                >Сброс</button>
              </div>
            </div>
          </div>
          <div class="w-catalog__contain">
            <!--          <h1 class="h1-page">{{product.title}}</h1>-->
            <section class="popular">
              <div class="title-wrapper">
                <div class="sort-catalog">
                  <div class="sort-catalog__item">Сортировать по:</div>
                  <div
                      class="sort-catalog__item"
                      v-for="(sortTitle, idx) in sortText"
                      :key="idx"
                  >
                    <button
                        @click="getSortId(sortTitle.id, idx)"
                        :class="{'active': activeSortId === idx}"
                    >{{ sortTitle.title}}</button>
                  </div>
                </div>
                <div class="title-wrapper__view t-view">
                  <span class="t-view__text">Показать:</span>
                  <span class="t-view__grid" @click="activeGrid = false">
                  <img v-if="!activeGrid" src="@/assets/images/view-grid.png">
                  <img v-if="activeGrid" src="@/assets/images/view-grid-noactive.png">
                </span>
                  <span class="t-view__list" @click="activeGrid = true" >
                  <img v-if="!activeGrid" src="@/assets/images/view-list.png">
                  <img v-if="activeGrid" src="@/assets/images/view-list-active.png">
                </span>
                </div>
              </div>
                <div v-if="!searchData.length">
                  <p
                      class="h1-page"
                      style="font-weight: 700; margin-top: 20px; text-align: center"
                  >
                    Ничего не найдено
                  </p>
                  <p
                      class="h1-page"
                      style="margin-top: 20px; text-align: center"
                  >
                    Извините, в данный момент этот товар недоступен
                  </p>
                </div>
                  <div v-if="!activeGrid"
                       class="new__products n-products page-products page-products-grid">

                    <div
                        class="cart-product n-products__item"
                        v-for="(prd, idx) in searchData"
                        :key="idx"
                        @click="getProduct(prd.id)"
                    >
                      <div class="cart-product__img">
                        <img :src="prd.image ? prd.image[0] : require('@/assets/images/no-img.jpg')">
                      </div>
                      <div class="cart-product__text">
                        {{ prd.title }}
                      </div>
                      <div class="wrap-price">
                        <div
                            v-if="prd.old_price !== prd.price"
                        >
                          <div class="cart-product__price cart-product__price--new">{{prd.price}} руб.</div>
                          <div class="cart-product__price cart-product__price--old">{{prd.old_price}} руб.</div>
                        </div>
                        <div
                            v-if="prd.old_price === prd.price"
                            class="cart-product__price"
                        >{{prd.price}} руб.</div>
                      </div>
                      <div class="cart-product__btn">
                        <button
                            class="btn-main"
                            v-if="prd.in_stock > 0"
                            @click.stop="getProductCart(prd.id)"
                        >В корзину</button>
                        <button
                            type="button"
                            v-if="prd.in_stock <= 0"
                            class="no-product"
                        >Нет в наличии</button>
                        <button @click.stop="$router.push(`/product/${prd.id}`)" class="btn-secondary">Подробнее</button>
                      </div>
                      <div class="cart-product__compare">
									<span>
                    <img src="@/assets/images/add-compare.svg">
                  </span>
                        <button  @click.stop="$router.push(`/compare/${prd.id}`)">Добавить к сравнению</button>
                      </div>
                    </div>
                  </div>
                  <div v-if="activeGrid"
                       class="new__products n-products page-products page-products-list">
                    <div class="cart-product n-products__item n-products__item--list"
                         v-for="(prd, idx) in  searchData"
                         :key="idx"
                         @click="getProduct(prd.id)"
                    >
                      <div class="flex-helper">
                        <div class="cart-product__img">
                          <img :src="prd.image ? prd.image[0] : require('@/assets/images/no-img.jpg')">
                        </div>
                        <div>
                          <div class="cart-product__text">
                            {{ prd.title }}
                          </div>
                          <div class="cart-product__compare">
											<span>
												<img src="@/assets/images/add-compare.svg">
											</span>
                            <button @click.stop="$router.push(`/compare/${prd.id}`)">Добавить к сравнению</button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="wrap-price">
                          <div
                              v-if="prd.old_price !== prd.price"
                          >
                            <div class="cart-product__price cart-product__price--new">{{prd.price}} руб.</div>
                            <div class="cart-product__price cart-product__price--old">{{prd.old_price}} руб.</div>
                          </div>
                          <div
                              v-if="prd.old_price === prd.price"
                              class="cart-product__price"
                          >{{prd.price}} руб.</div>
                        </div>
                        <div class="cart-product__btn">
                          <button
                              class="btn-main"
                              v-if="prd.in_stock > 0"
                              @click.stop="getProductCart(prd.id)"
                          >В корзину</button>
                          <button
                              type="button"
                              v-if="prd.in_stock <= 0"
                              class="no-product"
                          >Нет в наличии</button>
                          <button @click.stop="$router.push(`/product/${prd.id}`)" class="btn-secondary">Подробнее</button>
                        </div>
                      </div>
                    </div>
                  </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import MenuVertical from "@/components/menu/MenuVertical";
export default {
  name: "SearchProduct",
  data () {
    return {
      rangeOption: {
        dotSize: 14,
        width: 'auto',
        height: 6,
        contained: false,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 100000,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'active',
        tooltipPlacement: 'top',
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      rangeValue: [0, 100000],
      activeGrid: false,
      newsOrders: null,
      sortText: [
        {
          title: 'Наличию',
          id: 1
        },
        {
          title: 'Популярности',
          id: 2
        },
        {
          title: 'Цене',
          id: 3
        }

      ],
      activeSortId: null,
      filterData: {
        minPrice: 0,
        maxPrice: 100000,
        activeBrands: [],
        sortId: null,
      },
      activeBrands: [],
      brands: null
    }
  },
  components: {
    MenuVertical,
    VueSlider
  },
  async mounted() {
    window.scrollTo(0,0)
  },
  computed: {
    ...mapState(['searchText','brandsData', 'searchData']),
  },
  methods: {
    ...mapActions(['searchDataRemove']),
    getProduct (id){
      this.$router.push(`/product/${id}`)
    },
    async getSortId (id, idx) {
      this.activeSortId = idx
      this.filterData.sortId = id
      const res = (await this.axios.post('/product/filter', JSON.stringify(this.filterData)))
      if (res){
        this.searchDataRemove(res.data)
      }
    },
    getProductCart (id){
      this.$router.push(`/checkout/${id}`)
    },
    async getFilterData () {
      this.filterData.activeBrands = this.activeBrands
      this.filterData.minPrice = this.rangeValue[0]
      this.filterData.maxPrice = this.rangeValue[1]
      this.filterData.searchText = this.searchText
      const res = (await this.axios.post('/product/filter', JSON.stringify(this.filterData)))
      if (res){
        this.searchDataRemove(res.data)
      }

    },
    async resetFilterData() {
      this.activeBrands.forEach( (brand) => {
        brand.brandChack = false
      })
      this.rangeValue[0] = this.rangeOption.min
      this.rangeValue[1] = this.rangeOption.max
      this.filterData.minPrice = this.rangeOption.min
      this.filterData.maxPrice = this.rangeOption.max
      this.filterData.activeBrands = this.activeBrands
      this.filterData.sortId = null
      this.activeSortId = null
      this.searchDataRemove(this.searchText)
    },
    getActiveBrands  () {
      if (!this.activeBrands.length){
        if (this.brandsData){
          this.brandsData.forEach((brand) => {
            this.activeBrands.push({
              brandTitle: brand.title,
              brandId: brand.id,
              brandChack: false
            })
          })
        }
      }
      return this.activeBrands
    }
  },
  watch: {

  }
}
</script>

<style>
.vue-slider-rail{
  background-color: #fff;
  border: 1px solid #c5c5c5;
}
.vue-slider-process{
  background: #f60006
}
.vue-slider-dot-handle{
  background: #bababa;
  border-color: #bababa;
}
.vue-slider:hover .vue-slider-dot-handle:hover {
  border-color: #bababa;
}
.vue-slider{
  padding: 0 !important;
  height: 8px !important;
}
.vue-slider:hover .vue-slider-process {
  background-color: #f60006;
}
.t-view__grid{
  margin-right: 5px;
  margin-left: 5px;
}
.w-catalog__contain{
  margin-bottom: 30px;
}
</style>
