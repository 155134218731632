<template>
  <div class="about-company">
    <div class="container">
      <div class="about-company-img">
        <img :src="subPageData.image" alt="company" v-if="subPageData.image">
      </div>
      <div class="about-company-text">
        <div v-html="subPageData.description"></div>
      </div>
      <div
          class="about-company-video"
          v-if="subPageData.video"
      >
        <video-player class="video-player-box"
                      ref="videoPlayer"
                      :options="playerOptions"
                      :playsinline="true"
        >
        </video-player>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubPage",
  props: ['subPageData'],
  data () {
    return{
      playerOptions: {
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: this.subPageData.video
        }],
        poster: ''
      },
    }
  }
}
</script>

<style lang="scss">
.about-company {
  padding: 50px 0;
}

.about-company-img {
  width: 100%;

  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
}

.about-company-text {
  padding: 20px 0;
}

.about-company-video {
  padding: 20px 0;

  .video-player-box {
    width: 100%;

    .video-js {
      width: 100%;
      height: 400px;
      .vjs-big-play-button {
        top: calc(50% - 45px / 2);
        left: calc(50% - 90px / 2);
      }
    }
  }
}

@media (max-width: 768px) {
  .about-company-video {
    .video-player-box {
      width: 100%;

      .video-js {
        width: 100%;
        height: 300px;
      }
    }
  }

}
</style>
