<template>
  <section class="menu-vertical">
    <div class="container">
      <div class="menu-vertical__wrapper">
        <div
            class="menu-vertical__nav menu-vertical-nav"
            :class="{'active' : menuVerticalNav}"
            @click="getCategory"
            id="menu-vertical__nav"
        >
          <div class="menu-vertical-nav__icon">
            <img src="@/assets/images/menu-vertical__icon.svg">
          </div>
          <div class="menu-vertical-nav__name">Каталог</div>
          <ul
              class="menu-vertical-nav__list"
          >
            <li v-for="category in categories"
                :key="category.id"
                @click="getPageId(category.id)"
            >
              <router-link
                  :to="{path: `/category/${category.id}`}"
              >
                {{ category.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <div
            class="menu-vertical__search v-search"
            :class="{'active': searchActive}"
            id="v-search"
        >
          <input
              type="text"
              placeholder="Поиск товара"
              class="v-search__input"
              @input="getSearchData"
              v-model="textSearch"
          >
          <button
              class="v-search__btn"
              type="button"
              @click="getSearchPage"
          >
            <img src="@/assets/images/v-search.svg"></button>

          <div class="search-modal">
            <div
                class="search-modal__item"
                v-for="(searchItem, idx) in searchData"
                :key="idx"
                @click="getProduct(searchItem.id)"
            >
              <div>
                <img :src="searchItem.image ? searchItem.image[0] : require('@/assets/images/no-img.jpg')">
                <span>{{ searchItem.title }}</span>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div
        class="breadcrums"
        v-if="currentRouteName() !== 'Home'"
    >
      <div class="container">
        <div class="breadcrums__wrapper">
          <span class="breadcrums__item">
            <router-link :to="{path: '/'}">Главная</router-link>
          </span>
          <span
              class="breadcrums__item"
              v-if="sub1"
          >
          <router-link :to="{path: '/category/' + category_id}">{{sub1}}</router-link>
          </span>
          <span
              class="breadcrums__item"
              v-if="sub2"
          >
            {{searchSub ? searchSub : sub2}}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "MenuVertical",
  props: ['sub1','sub2','search', 'category_id'],
  data (){
    return{
      menuVerticalNav: false,
      searchActive: false,
      textSearch: '',
      searchSub: '',
      page: 1
    }
  },
  methods: {
    ...mapActions([
      'categoryIdRemove',
      'searchTextRemove',
      'productIdRemove',
      'searchDataRemove',
      'categoriesRemove'
    ]),
    getPageId (id) {
      this.categoryIdRemove(id)
    },
    async getSearchData () {
      this.searchActive = true
      this.searchDataRemove(this.textSearch)
    },
    currentRouteName() {
      return this.$route.name;
    },
    closeSearchModal () {
      this.searchActive = false
    },
    getProduct (id) {
      this.$router.push(`/product/${id}`)
      this.productIdRemove(id)
    },
    getSearchPage () {
      let currentPath = window.location.hash.slice(1)
      if (currentPath !== `/search-product`){
        this.$router.push(`/search-product`)
      }
      if (!this.textSearch){
        this.searchDataRemove(this.textSearch)
      }
      this.searchTextRemove(this.textSearch)
      this.searchSub = this.searchText
    },
    async getCategory () {
      this.categoriesRemove()
      this.menuVerticalNav = !this.menuVerticalNav
    },
  },
  mounted() {
    window.addEventListener('click', this.closeSearchModal)
    this.textSearch = this.searchText
    let currentPath = window.location.hash.slice(1)
    this.searchSub = this.textSearch
    if (currentPath !== '/search-product'){
      this.searchSub = ''
    }
  },
  computed: {
    ...mapState(['searchText', 'searchData', 'categories'])
  },
  async created() {

  },
}
</script>

<style>

</style>
