<template>
<div>
  <Spiner v-if="spiner"/>
  <div class="about-company">
    <div class="container" v-if="trusted">
      <h1>{{ trusted.title }}</h1>
      <div class="about-company-img">
        <img :src="trusted.src ? trusted.src : require('@/assets/images/no-img.jpg')" alt="company">
      </div>
      <div class="about-company-text">
        <p v-html="trusted.description"></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import Spiner from "@/components/global-component/Spiner";
export default {
  name: "Trusted",
  components: {Spiner},
  data (){
    return{
      trusted: null,
      spiner: true
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
  async created () {
    this.trusted = (await this.axios.get(`/trusted/${this.$route.params.trustedId}`)).data || []
    if (this.trusted){
      this.spiner = false
    }
  },
}
</script>

<style scoped lang="scss">
.about-company{
  padding: 50px 0;
}
h1{
  text-align: center;
}
.about-company-img{
  width: 100%;
  img{
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
}
.about-company-text{
  padding: 30px 0;
}
.about-company-video{
  padding: 30px 0 10px;
  .video-player-box{
    width: 100%;
    .video-js{
      width: 100%;
    }
  }
  .vjs-big-play-button{
    top: calc(50% - 45px / 2);
    left: calc(50% - 90px / 2);
  }
}
@media (max-width: 768px){
  .about-company-video{
    .video-player-box{
      width: 100%;
      .video-js{
        width: 100%;
        height: 300px;
      }
    }
  }

}
</style>