<template>
  <div>
    <Spiner
        v-if="!contactData"
    />
    <SubPage
        v-if="contactData"
        :subPageData="contactData"
    />
  </div>
</template>

<script>
import SubPage from "@/components/global-component/SubPage";
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import Spiner from "@/components/global-component/Spiner";
export default {
  name: "Service",
  components: {
    SubPage,
    Spiner
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['contactDataRemove'])
  },
  computed: {
    ...mapState(['contactData'])
  },
  mounted() {
    window.scrollTo(0, 0)
    this.contactDataRemove()
  }
}
</script>

<style scoped>

</style>
