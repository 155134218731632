import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import Category from "@/views/Category";
import Product from "@/views/Product";
import AboutCompany from "@/views/AboutCompany";
import Checkout from "@/views/Checkout";
import Compare from "@/views/Compare";
import Service from "@/views/Service";
import PaymentShipping from "@/views/PaymentShipping";
import Leasing from "@/views/Leasing";
import Contact from "@/views/Contact";
import NewProduct from "@/views/NewProduct";
import SearchProduct from "@/views/SearchProduct";
import Trusted from "@/views/Trusted";
import TrustedAll from "@/views/TrustedAll";
import Brand from "@/views/Brand";
import Error from "@/views/Error";
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    component: Error
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/edit_page/lh9-2as7nbas_J3hNMM32',
    name: 'Home',
    component: Home
  },
  {
    path: '/category/:categoryId?',
    name: 'Category',
    component: Category
  },
  {
    path: '/product/:orderId',
    name: 'Product',
    component: Product
  },
  {
    path: '/checkout/:orderId?',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/compare/:compareId?',
    name: 'Compare',
    component: Compare
  },
  {
    path: '/about-company',
    name: 'AboutCompany',
    component: AboutCompany
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/payment_shipping',
    name: 'PaymentShipping',
    component: PaymentShipping
  },
  {
    path: '/leasing',
    name: 'Leasing',
    component: Leasing
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/new-product',
    name: 'NewProduct',
    component: NewProduct
  },
  {
    path: '/search-product',
    name: 'SearchProduct',
    component: SearchProduct
  },
  {
    path: '/trusted/:trustedId',
    name: 'Trusted',
    component: Trusted
  },
  {
    path: '/trusted-all',
    name: 'TrustedAll',
    component: TrustedAll
  },
  {
    path: '/brand/:brandId',
    name: 'Brand',
    component: Brand
  }
]

const router = new VueRouter({
  mode: 'hash',

  base: process.env.BASE_URL,
  routes
})

export default router
