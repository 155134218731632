<template>
  <section class="clients gray-block">
    <div class="container">
      <div class="clients__list c-list">
        <router-link
            :to="{path: trusted.description ? `trusted/${trusted.id}` : '/trusted-all'}"
            class="c-list__item"
            v-for="(trusted, idx) in trusteds.data"
            :key="idx"
        >
          <img :src="trusted.src ? trusted.src : require('@/assets/images/no-img.jpg')">
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "TrustedAll",
  data () {
    return{

    }
  },
  computed: {
    ...mapState(['trusteds'])
  },
}
</script>

<style scoped>

</style>
