<template>
  <div>
    <MenuVertical
        sub1="Сравнивать"
    />
    <section class="catalog">
      <div class="container">
        <div class="catalog__wrapper slidebar-compare">
          <div>
            <div class="slidebar-compare__wrap">
              <div
                  class="slidebar-compare__item"
                  @click.stop="resetCompare()"
              >
                <img src="@/assets/images/compare-clear.png">
                <span>Очистить список</span>
              </div>
              <div
                  class="slidebar-compare__item"
              >
                <input type="radio" class="filter_inp" name="filter_left" @change="allCharacter"
                       :checked="allCompareFields">
                <span>Все характеристики </span>
              </div>
              <div
                  class="slidebar-compare__item"
              >
                <input type="radio" class="filter_inp" name="filter_left" :checked="!allCompareFields"
                       @change="allCompare">
                <span>Только различающиеся </span>
              </div>
            </div>
            <div class="slidebar-compare__category_wrap">
              <div
                  v-for="item in categoriesAttr"
                  :key="item.id"
                  class="slidebar-compare__category_item"
                  @click.stop="getAttribute(item.id)"
              >
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>

          <div class="products-list">
            <div class="new__products n-products page-products products-list__compare">
              <div
                  class="cart-product n-products__item"
                  v-for="(product, idx) in compareProduct"
                  :key="idx"
                  @click.stop="$router.push(`/product/${product.id}`)"
              >
                <img
                    src="@/assets/images/close-compare.png"
                    class="close-compare"
                    @click.stop="deleteCompare(idx)"
                >
                <div class="cart-product__img">
                  <img :src="product.image ? product.image[0] : require('@/assets/images/no-img.jpg')">
                </div>
                <div class="cart-product__text">{{ product.title }}</div>
                <div class="wrap-price">
                  <div class="cart-product__price">{{ product.price }} руб.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="table-compare">
      <div v-if="!spinner" class="container">
        <div class="table-compare__wrap t-compare">
          <div class="t-compare__tr"
               v-for="(item, i) in attributes"
               :key="i"
          >
            <div class="t-compare__td">{{ item.attribute.title }}</div>
            <div
                v-for="(prod, li) in compareProduct"
                :key="li"
                class="t-compare__td"
            >
              <p v-for="val in prod.compare" :key="val.id">
                {{ item.attribute.id === val.attribute_id ? val.value : '' }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Spiner v-if="spinner"/>

    </section>
    <div class="container">
      <MapInfo/>
    </div>
  </div>
</template>

<script>
import MenuVertical from "@/components/menu/MenuVertical";
import MapInfo from "@/components/global-component/MapInfo";
import {mapState, mapActions} from "vuex/dist/vuex.esm.browser";
import Spiner from "@/components/global-component/Spiner";


export default {
  name: "Compare",
  components: {MapInfo, MenuVertical, Spiner},
  data() {
    return {
      spinner: true,
      activeCompare: [],
      allCompareFields: true,
      attributes: [],
      categoriesAttr: [],
      compareProduct: [],
      diffAttributes: []
    }
  },

  async created() {
    let userID = localStorage.getItem('userId')
    userID && await this.axios.get('/card/compare_attributes/' + userID).then((res) => {
      if (res.data.attributes.length > 0) {
        this.attributes = res.data.attributes
        this.spinner = false
      }
      if (res.data.categories.length > 0) {
        this.categoriesAttr = res.data.categories
      }
      if (res.data.compare_products) {
        this.compareProduct = res.data.compare_products
      }
    })
  },

  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapState(['userId'])
  },

  methods: {
    ...mapActions(['compareProductRemove']),
    async deleteCompare(idx) {
      this.compareProduct.splice(idx, 1)
      this.activeCompare.splice(idx, 1)
      await this.axios.post('/card/add', JSON.stringify({
        "userId": this.userId,
        "cardProduct": null,
        "compareProduct": this.activeCompare.length ? this.activeCompare : !this.activeCompare.length
      }))
    },
    async resetCompare() {
      this.allCompareFields = true
      this.compareProductRemove([])
      this.activeCompare = []
      this.compareProduct = []
      await this.axios.post('/card/add', JSON.stringify({
        "userId": this.userId,
        "cardProduct": null,
        "compareProduct": !this.activeCompare.length
      }))
      location.reload();
    },

    async allCharacter() {
      this.spinner = true

      let userID = localStorage.getItem('userId')
      userID && await this.axios.get('/card/compare_attributes/' + userID).then((res) => {
        if (res.data.attributes.length > 0) {
          this.attributes = res.data.attributes
          this.spinner = false
        }
      })
      this.allCompareFields = true
      this.spinner = false
    },

    async allCompare() {
      this.compareProduct[0].compare.map((el) => {
        this.compareProduct.map((item, key) => {
          if (key !== 0) {
            item.compare.map((attr) => {
              if (el.value === attr.value) {
                this.attributes.map((attrId, key) => {
                  if (attrId.attribute.id == attr.attribute_id) {
                    this.attributes.splice(key, 1)
                    this.spinner = false
                  }
                })
              }
            })
          }
        })
      })
      this.allCompareFields = false
    },

    async getAttribute(id) {
      let userID = localStorage.getItem('userId')
      userID && await this.axios.get('/card/get-compare-product/' + id + "/" + userID).then((res) => {
        this.compareProduct = []
        if (res.data.attributes.length > 0) {
          this.attributes = res.data.attributes
          this.newAttributes = res.data.attributes

        }
        if (res.data.compare_products) {
          this.compareProduct = res.data.compare_products
        }
      })
    }
  },

}
</script>

<style>
.slidebar-compare__category_wrap {
  border: 1px solid #b8d4c4;
  border-radius: 5px;
  height: 200px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 30px;
  overflow-y: scroll;
  padding: 10px;
}

.slidebar-compare__category_item {
  cursor: pointer;
}

.slidebar-compare__category_item:hover {
  color: #1c8f4e;
}

p {
  margin: 0;
  padding: 0;
}
</style>
