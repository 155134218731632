<template>
  <div class="spinerLoad" v-if="isLoad">
    <Spiner/>
  </div>
  <div v-else class="home">
    <section class="banner-section">
      <div class="swiper banner-swiper">
        <div class="swiper-wrapper banner banner-section__wrapper">
          <div
              class="swiper-slide banner__item"
              v-for="(slide, idx) in general"
              :key="idx"
              style="height: 309px"
          >
            <img :src="slide.src" style="height: 100%; width: 100%; object-fit: cover">
            <div class="banner__content">

              <div class="container pos_rel">
                <div class="banner__title">{{ slide.title }}</div>
                <div class="banner__subtitle">{{ slide.description }}</div>
                <img src="@/assets/images/edit_red.png"
                     v-if="isEdit" class="isEdit_container"
                     @click="editElement('general', slide.id)"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <MenuVertical/>

    <section class="catalog-3d">
      <div class="container">
        <HomeIcon/>
      </div>
    </section>

    <section class="advantages">
      <div class="container">
        <div class="title"><span class="title__caption">Преимущества</span></div>
        <div class="adv-wrapper">
          <div class="adv-wrapper__item adv-wrapper-item"
               v-for="(el, index) in homeData.advantages"
               :key="index"
          >
            <div class="adv-wrapper-item__icon">
              <img :src="el.src">
            </div>
            <div class="adv-wrapper-item__name">{{ el.title }}</div>
            <img src="@/assets/images/edit_red.png" class="" v-if="isEdit" @click="editElement('advantages', el.id)">
          </div>

        </div>
      </div>
    </section>

    <section class="new gray-block">
      <div class="container">
        <div class="title-wrapper">
          <div class="title"><span class="title__caption">Новинки</span></div>
          <router-link :to="{path: '/new-product'}" class="title-wrapper__link">Все новинки</router-link>
        </div>
        <div class="new__products n-products">
          <div
              class="cart-product n-products__item"
              v-for="(order, idx) in getNewOrders()"
              :key="idx"
              @click="$router.push(`/product/${order.id}`)"
          >
            <div class="cart-product__img">
              <img :src="order.img[0] ? order.img[0] : require('@/assets/images/no-img.jpg')">
            </div>
            <div class="cart-product__text">
              {{ order.title }}
            </div>
            <div class="wrap-price">
              <div
                  v-if="order.old_price !== order.price"
              >
                <div class="cart-product__price cart-product__price--new">{{ order.custom_price }} руб.</div>
                <div class="cart-product__price cart-product__price--old">{{ order.custom_old_price }} руб.</div>
              </div>
              <div
                  v-if="order.old_price === order.price"
                  class="cart-product__price"
              >{{ order.custom_price }} руб.
              </div>
            </div>
            <div class="cart-product__btn">
              <button
                  class="btn-main"
                  v-if="order.in_stock > 0"
                  @click.stop="getNewProduct(order.id)"
              >В корзину
              </button>
              <button
                  type="button"
                  v-if="order.in_stock <= 0"
                  class="no-product"
              >Нет в наличии
              </button>
              <router-link :to="{path: '/payment_shipping'}" class="btn-secondary">Подробнее</router-link>
            </div>
            <div class="cart-product__compare">
              <div v-if="compareProductIDs.length && compareProductIDs.includes(order.id)">
                <button type="button"
                >
                  Уже добавлено
                </button>
              </div>
              <div v-else style="display: flex">
                       <span>
                          <img src="@/assets/images/add-compare.svg">
                       </span>
                <button type="button"
                        @click.stop="addToCompare(order.id, order)"
                >
                  Добавить к сравнению
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="text-section">
      <div class="container">
        <div class="text-default text-section__info">
          <p v-html="homeData.leasing_desc"></p>
        </div>
        <router-link :to="{path: '/leasing'}" class="btn-more">Подробнее</router-link>
      </div>
    </section>

    <section class="brand">
      <div class="container">
        <div class="title">
          <span class="title__caption">Бренды</span>
        </div>
        <div class="brand__slider swiper">
          <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                v-for="(brand,idx) in brandsData"
                :key="idx"
            >
              <button @click.stop="$router.push(`/brand/${brand.id}`)">
                <img :src="brand.image ? brand.image : require('@/assets/images/no-img.jpg')" alt="img"/>
              </button>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </section>

    <section class="clients gray-block">
      <div class="container">
        <div class="title-wrapper">
          <div class="title"><span class="title__caption">Нам доверяют</span></div>
          <router-link :to="{path: '/trusted-all'}" class="title-wrapper__link">Все клиенты</router-link>
        </div>
        <div class="clients__list c-list">
          <router-link
              :to="{path: trusted.description ? `trusted/${trusted.id}` : '/'}"
              class="c-list__item"
              v-for="(trusted, idx) in trusteds.data"
              :key="idx"
          >

            <img :src="trusted.src ? trusted.src  : require('@/assets/images/no-img.jpg')" alt="img">
          </router-link>
        </div>
      </div>
    </section>

    <section class="text-bg">
      <div class="container text-bg__title custom_color_title">
        <div v-html="homeData.company_desc" class="home_company_style"></div>
        <router-link :to="{path: '/about-company'}" class="text-bg__btn btn-more">Подробнее</router-link>
      </div>
    </section>
    <Map :data="homeData.seo_text"/>
  </div>
</template>

<script>
import Spiner from "@/components/global-component/Spiner";

import Map from "@/components/global-component/Map";
import MenuVertical from "@/components/menu/MenuVertical";
import Swiper from "@/assets/js/swiper-bundle.min"
import 'swiper/swiper-bundle.css'
import HomeIcon from "@/components/menu/HomeIcon";
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: 'Home',
  components: {
    MenuVertical,
    Map,
    HomeIcon,
    Spiner
  },
  data() {
    return {
      compareProductIDs: [],
      general: null,
      leasingShow: false,
      textShow: false,
      isLoad: true,
      isEdit: false,
      isEditData: null,
    }
  },
  mounted() {
    if (!this.compareProductIDs.length && this.compareProduct && this.compareProduct.length) {
      this.compareProduct.map((item) => {
        this.compareProductIDs.push(item.id)
      })
    }

    setTimeout(() => {
      this.isLoad = false
    }, 7000)
    window.scrollTo(0, 0)
    if (this.homeData) {
      this.isLoad = false
      this.general = this.homeData.slider
    }
  },
  watch: {
    compareProduct() {
      this.compareProduct.map((item) => {
        this.compareProductIDs.push(item.id)
      })
    },

    isLoad() {
      setTimeout(() => {
        new Swiper(".banner-swiper", {
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
        new Swiper(".brand__slider", {
          slidesPerView: 5,
          loop: false,
          spaceBetween: 30,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            320: {
              slidesPerView: 2,
            },
            991: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 5,
            }
          }
        });
      }, 100)

    },
    homeData: function () {
      this.general = this.homeData.slider
      this.isLoad = false
    },

    edit_from_admin() {
      if (this.edit_from_admin) {
        this.isEdit = true
        this.isEditData = this.edit_from_admin
      }
      this.edit_from_admin
    },

    userIdData: async function () {
      let cardProducts = []
      if (this.userIdData){
        this.userIdData.forEach((item) => {
          cardProducts.push({
            id: item.id,
            count: item.count
          })
        })
      }
      await this.axios.post('/card/add', JSON.stringify({
        "userId": this.userId,
        "cardProduct": cardProducts,
        "compareProduct": null
      }))
    },
  },
  methods: {
    ...mapActions(['brandsDataRemove', 'newOrdersRemove', 'trustedsRemove', 'editFromAdmin', 'userIdDataRemove', 'compareProductRemove']),
    async getNewProduct(id) {
      let res = (await this.axios.get('/product/' + id)).data
      if (res) {
        let orderPush = true
        this.userIdData.forEach((item) => {
          if (Number(item.id) === id) {
            orderPush = false
          }
        })
        if (orderPush) {
          let orders = this.userIdData
          orders.push(res)
          this.userIdDataRemove(orders)
        }

        this.$swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Успешно добавлен в корзину',
          showConfirmButton: false,
          timer: 1500
        })
      }
    },

    async addToCompare(id){
      this.activeCompare = []
      const res = (await this.axios.post('/card/get', JSON.stringify({id: this.userId}))).data
      if (res.compare_products) {
        res.compare_products.forEach((val) => {
          this.activeCompare.push({
            id: val.id
          })
        })
      }

      let compareAdd = true
      this.activeCompare.forEach((val) => {
        if (val.id === id) {
          compareAdd = false
        }
      })
      if (compareAdd) {
        this.activeCompare.unshift({id})
      }

      let response = await this.axios.post('/card/add', JSON.stringify({
        "userId": this.userId,
        "cardProduct": null,
        "compareProduct": this.activeCompare
      }))

      if (response.data.Message == 'success') {
        const res1 = (await this.axios.post('/card/get', JSON.stringify({id: this.userId}))).data
        this.compareProductRemove(res1.compare_products)
      }

      this.$swal.fire({
        position: 'top-end',
        icon: 'success',
        text: 'Успешно добавлено в сравнение',
        showConfirmButton: false,
        timer: 1500
      })
    },

    getNewOrders() {
      let res = []
      if (this.newOrders) {
        res = this.newOrders.slice(0, 4)
      }
      return res
    },

    editElement(el, id) {
      if (el === "advantages" && id) {
        return window.open(this.isEditData[el] + '/' + id + '/edit', "MsgWindow", "width=700,height=500");
      }
      if (id) {
        return window.open(this.isEditData[el] + '/' + id + '/edit_slider', "MsgWindow", "width=700,height=500");
      }
      window.open(this.isEditData[el], "MsgWindow", "width=700,height=500");
    },
  },
  computed: {
    ...mapState(['compareProduct', 'homeData','userId', 'brandsData', 'newOrders', 'trusteds', 'edit_from_admin', 'userIdData'])
  },

}
</script>
<style>
.show {
  opacity: 0 !important;
}

.spinerLoad {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1111;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isEdit_container {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.pos_rel {
  position: relative;
}

</style>
