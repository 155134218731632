<template>
  <div class="container">
    <section class="popular">
      <div class="title-wrapper">
        <div class="title-wrapper__view t-view">
          <span class="t-view__text">Показать:</span>
          <span class="t-view__grid" @click="activeGrid = false">
                  <img v-if="!activeGrid" src="@/assets/images/view-grid.png">
                  <img v-if="activeGrid" src="@/assets/images/view-grid-noactive.png">
                </span>
          <span class="t-view__list" @click="activeGrid = true" >
                  <img v-if="!activeGrid" src="@/assets/images/view-list.png">
                  <img v-if="activeGrid" src="@/assets/images/view-list-active.png">
                </span>
        </div>
      </div>
      <div v-if="!activeGrid"
           class="new__products n-products page-products page-products-grid"
      >
        <div
            class="cart-product n-products__item"
            v-for="(news, idx) in newOrders"
            :key="idx"
            @click="getProduct(news.id)"
        >
          <div class="cart-product__img">
            <img :src="news.img[0] ? news.img[0] : require('@/assets/images/no-img.jpg')">
          </div>
          <div class="cart-product__text">
            {{ news.title }}
          </div>
          <div class="wrap-price">
            <div
                v-if="news.old_price !== news.price"
            >
              <div class="cart-product__price cart-product__price--new">{{news.price}} руб.</div>
              <div class="cart-product__price cart-product__price--old">{{news.old_price}} руб.</div>
            </div>
            <div
                v-if="news.old_price === news.price"
                class="cart-product__price"
            >{{news.price}} руб.</div>
          </div>
          <div class="cart-product__btn">
            <button
                class="btn-main"
                v-if="news.in_stock > 0"
                @click.stop="getCardNewProduct(news.id)"
            >В корзину</button>
            <button
                type="button"
                v-if="news.in_stock <= 0"
                class="no-product"
            >Нет в наличии</button>
            <router-link :to="{path: '/payment_shipping'}" class="btn-secondary">Подробнее</router-link>
          </div>
          <div class="cart-product__compare">
									<span>
                    <img src="@/assets/images/add-compare.svg">
                  </span>
            <button @click.stop="$router.push(`/compare/${news.id}`)">Добавить к сравнению</button>
          </div>
        </div>
      </div>
      <div v-if="activeGrid"
           class="new__products n-products page-products page-products-list"
      >
        <div class="cart-product n-products__item n-products__item--list"
             v-for="(news, idx) in newOrders"
             :key="idx"
        >
          <div class="flex-helper">
            <div class="cart-product__img">
              <img :src="news.img[0] ? news.img[0] : require('@/assets/images/no-img.jpg')">
            </div>
            <div>
              <div class="cart-product__text">
                {{ news.title }}
              </div>
              <div class="cart-product__compare">
											<span>
												<img src="@/assets/images/add-compare.svg">
											</span>
                <button @click.stop="$router.push(`/compare/${news.id}`)">Добавить к сравнению</button>
              </div>
            </div>
          </div>
          <div>
            <div class="wrap-price">
              <div
                  v-if="news.old_price !== news.price"
              >
                <div class="cart-product__price cart-product__price--new">{{news.price}} руб.</div>
                <div class="cart-product__price cart-product__price--old">{{news.old_price}} руб.</div>
              </div>
              <div
                  v-if="news.old_price === news.price"
                  class="cart-product__price"
              >{{news.price}} руб.</div>
            </div>
            <div class="cart-product__btn">
              <button
                  class="btn-main"
                  v-if="news.in_stock > 0"
                  @click.stop="getCardNewProduct(news.id)"
              >В корзину</button>
              <button
                  type="button"
                  v-if="news.in_stock <= 0"
                  class="no-product"
              >Нет в наличии</button>
              <router-link :to="{path: '/payment_shipping'}" class="btn-secondary">Подробнее</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="newOrders.length" v-observe-visibility="getPage"></div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";

export default {
  name: "NewProduct",
  data () {
    return {
      activeGrid: false,
      page: 1
    }
  },
  components: {
  },
  mounted() {
    window.scrollTo(0,0)
  },
  computed: {
    ...mapState(['newOrders', 'newOrdersPagination'])
  },
  methods: {
    ...mapActions(['newOrdersRemove']),
    getProduct (id){
      this.$router.push(`product/${id}`)
    },
    getCardNewProduct (id) {
      this.$router.push(`/checkout/${id}`)
    },
    getPage (isVisible) {
      if (!isVisible) return
      if (this.page >= this.newOrdersPagination.last_page) return;
      this.page++
      this.newOrdersRemove(this.page)
    }
  },
}
</script>

<style scoped>
.title-wrapper{
  justify-content: flex-end;
}
.popular{
  padding-bottom: 50px;
}
</style>