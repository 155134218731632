<template>
  <div>
    <Spiner v-if="spiner"/>
    <div class="about-company" v-if="!spiner">
      <div class="container">
        <h1>{{ brand.title }}</h1>
        <div class="about-company-img">
          <img :src="brand.image ? brand.image : require('@/assets/images/no-img.jpg')" alt="company">
        </div>
        <div class="about-company-text">
          <div v-html="brand.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spiner from "@/components/global-component/Spiner";
export default {
  name: "Brand",
  components: {Spiner},
  data () {
    return {
      brand: null,
      spiner: true
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
  async created () {
    this.brand = (await this.axios.get(`/brand/${this.$route.params.brandId}`)).data || []
    if (this.brand){
      this.spiner = false
    }
  },
}
</script>

<style scoped lang="scss">
h1{
  text-align: center;
}
.about-company-img{
  width: 100%;
  img{
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
}
.about-company-text{
  padding: 30px 0;
}
.about-company-video{
  padding: 30px 0 10px;
  .video-player-box{
    width: 100%;
    .video-js{
      width: 100%;
    }
  }
  .vjs-big-play-button{
    top: calc(50% - 45px / 2);
    left: calc(50% - 90px / 2);
  }
}
@media (max-width: 768px){
  .about-company-video{
    .video-player-box{
      width: 100%;
      .video-js{
        width: 100%;
        height: 300px;
      }
    }
  }

}
</style>