<template>
  <footer>
    <div class="container">
      <div class="footer">
        <div class="footer__top f-top">
          <div class="f-top__logo">
            <router-link :to="{path: '/'}">
              <img src="@/assets/images/logo.png">
            </router-link>
          </div>
          <div class="f-top__menu f-menu">
            <ul class="f-menu__ul">
              <li class="f-menu__li">
                <router-link :to="{path: '/about-company'}">О нас</router-link>
              </li>
              <li class="f-menu__li">
                <router-link :to="{path: '/service'}">Сервис</router-link>
              </li>
              <li class="f-menu__li">
                <router-link :to="{path: '/payment_shipping'}"> Доставка и оплата</router-link>
              </li>
              <li class="f-menu__li">
                <router-link :to="{path: '/leasing'}">Лизинг</router-link>
              </li>
              <li class="f-menu__li">
                <router-link :to="{path: '/contact'}"> Контакты</router-link>
              </li>
            </ul>
          </div>
          <div class="f-top__info f-info">
            <div class="f-info__address">
              {{address}}
            </div>
            <div class="f-info__email">
              <span>Email: </span> <a href="mailto:info@aoed.ru">info@aoed.ru</a>
            </div>
            <div class="f-info__whatsapp">
              <img src="@/assets/images/whatsapp.svg">
              <span>Напишите нам в <a href="https://wa.me/" target="_blank">WhatsApp</a></span>
            </div>
            <div class="f-info__question f-info-question">
              <div
                  class="h-link-order"
                  id="h-link-order"
                  :class="{'active' : orderNumberModal}"
              >
                <img src="@/assets/images/question.svg"  @click="getOrderModal">
                <span  @click="getOrderModal">Что с моим заказом?</span>
                <div
                    class="h-link-order__form w-order-form"
                >
                  <form>
                    <div class="w-order-form__title">Введите номер заказа</div>
                    <input
                        type="text"
                        class="w-order-form__input"
                        v-model="orderNumber"
                        :class="{'w-add-sale__input-error' : $v.orderNumber.$dirty && $v.orderNumber.$error}"
                    >
                    <button
                        class="w-order-form__btn"
                        id="w-order-form__btn"
                        type="button"
                        @click="getNumberPrice"
                    >Узнать</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="f-top__contact f-t--item">
            <a :href="`tel:${phone_1}`" class="f-t--item__phone" v-html="getPhoneNumber(phone_1)"></a>
            <a :href="`tel:${phone_2}`" class="f-t--item__phone" v-html="getPhoneNumber(phone_2)"></a>
            <div class="f-t--item__social">Мы в соц сетях:</div>
            <div class="f-t--item__socials">
<!--              <a href="#" class=""><img src="@/assets/images/footer-fb.png"></a>-->
              <a href="https://vk.com/" class=""><img src="@/assets/images/footer-vk.png"></a>
<!--              <a href="#" class=""><img src="@/assets/images/footer-insta.png"></a>-->
            </div>
          </div>
        </div>
        <div class="footer-bottom f-bottom">
          <div class="f-bottom__copyright">2022. Эквип Дон. Все права защищены</div>
          <div class="f-bottom__web">
            <a href="https://web-ra.ru">Разработано в <span>web-ra.ru</span>
              <img src="@/assets/images/logo-company.png">
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapState} from "vuex/dist/vuex.esm.browser";
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
export default {
  name: "Footer",
  props: ['general'],
  mixins: [validationMixin],
  data () {
    return{
      phone_1: '',
      phone_2: '',
      address: '',
      orderNumberModal: false,
      orderNumber: null,
    }
  },
  methods: {
    getPhoneNumber (number) {
      if (number){
        let text_1 = ''
        let text_2 = ''
        for (let i = 0; i < number.length; i++) {
          if (i < 10){
            text_1 += number[i]
            continue;
          }
          text_2 += number[i]
        }
        return '<span>' + text_1 + '</span>' + text_2
      }
    },
    getOrderModal () {
      this.orderNumberModal =  !this.orderNumberModal
      if (!this.orderNumberModal){
        this.$v.orderNumber.$reset()
        this.orderNumber = null
      }
    },
    async getNumberPrice () {
      this.$v.orderNumber.$touch()
      if (!this.$v.orderNumber.$error){
       const res =  await this.axios.post('/order/about_order', JSON.stringify({
         "message": this.orderNumber,
       }) )
        if (res.status  === 200){
          this.$v.orderNumber.$reset()
          this.orderNumber = null
          this.orderNumberModal = false
          this.$fire({
            position: 'top',
            text: "Номер заказа отправлен",
            type: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    },
  },
  validations: {
    orderNumber: {
      numeric,
      required
    }
  },
  computed: {
    ...mapState(['homeData']),

  },
  watch: {
    homeData: function () {
      this.phone_1 = this.homeData.phone
      this.phone_2 = this.homeData.phone_2
      this.address = this.homeData.address
    }
  },
  mounted () {
    if (this.homeData){
      this.phone_1 = this.homeData.phone
      this.phone_2 = this.homeData.phone_2
      this.address = this.homeData.address
    }
  }
}
</script>

<style scoped lang="scss">
.h-link-order{
  cursor: pointer;
  align-items: center;
  display: flex;
  text-decoration: none;

  img{
    display: block;
    margin-right: 5px;
  }
  span{
    text-decoration: none;
    color: #000;
    border-bottom: 1px dashed #000;
    cursor: pointer;
  }
}
</style>