<template>
  <header>
    <div class="header">
      <div class="header__top header-top">
        <div class="container">
          <div class="header-top__item header-top__item--city h-t-item">
            <div class="h-t-item__icon">
              <img src="@/assets/images/location-icon.svg" alt="logo">
            </div>
            <div class="h-t-item__name" @click="$modal.show('city')">{{subdomainName}}</div>
          </div>
          <div class="header-top__item header-top__item--menu">
            <nav>
              <ul v-if="homeData && homeData.header_links">
                <li class="pos_rel"
                    v-for="(el, index) in homeData.header_links"
                    :key="index+1"
                >
                  <router-link
                      :to="{path: '/' + index}"
                      exact-active-class="active-nav-list"
                  >{{ el }}
                  </router-link>
                  <img src="@/assets/images/edit_red.png" class="edit_from_admin" v-if="isEdit" @click="editElement(index)">

                </li>

              </ul>
            </nav>
          </div>
          <div class="header-top__item header-top__item--social">
            <a href="https://vk.com/"><img src="@/assets/images/vk.png"></a>
            <a href="https://web.telegram.org/k/"><img src="@/assets/images/tg.png"></a>
          </div>
        </div>
      </div>
      <div class="header__bottom header-bottom pos_rel">
        <div class="container">
          <div class="header-bottom__item header-bottom__item--logo h-b-item">
            <router-link :to="{path: '/'}">
              <img src="@/assets/images/logo.png">
            </router-link>
            <div class="h-b-item__credo">
              Продажа и ремонт сервисного
              оборудования с доставкой по РФ
            </div>
          </div>
          <div class="header-bottom__item header-bottom__item--contact h-b-item">
            <a :href="`tel:+ 7 (903) 401-24-14`" class="h-b-item__phone" v-html="getPhoneNumber(phone_1)"></a>
            <a :href="`tel:+ 7 (903) 401-24-14`" class="h-b-item__phone" v-html="getPhoneNumber(phone_2)"></a>
            <div class="h-b-item__whatsapp">
              <img src="@/assets/images/whatsapp.svg">
              <span>Напишите нам в
                  <a href="https://wa.me/">WhatsApp</a>
                </span>
            </div>
            <div class="h-b-item__call" @click="$modal.show('back-call')">
              <span>Заказать обратный звонок</span>
            </div>
          </div>
          <div class="header-bottom__item header-bottom__item--cart">
            <div class="header-cart">
              <router-link :to="{path: '/compare'}" tag="div" class="header-cart__compare">
                <img src="@/assets/images/icon-compare.svg">
                <span>{{ totalPriceCompare() }}</span>
              </router-link>
              <router-link :to="{path: `/checkout/all`}" tag="div" class="header-cart__products">
                <img src="@/assets/images/icon-basket.svg">
                <span>{{ countProducts() }} позиций на {{ getTotalPrice() }} ₽</span>
              </router-link>
              <div
                  class="menu__btn"
                  @click="getMobileMenu"
                  :class="{'menu__btn_active': mobileMenu}"
              >
                <span class="menu__btn-line"></span>
                <span class="menu__btn-line"></span>
                <span class="menu__btn-line"></span>
              </div>
            </div>
            <div
                class="h-link-order"
                id="h-link-order"
                :class="{'active' : orderNumberModal}"
            >
              <img src="@/assets/images/question.svg" @click="getOrderModal">
              <span @click="getOrderModal">Что с моим заказом?</span>
              <div
                  class="h-link-order__form w-order-form"
              >
                <form>
                  <div class="w-order-form__title">Введите номер заказа</div>
                  <input
                      type="text"
                      class="w-order-form__input"
                      v-model="orderNumber"
                      :class="{'w-add-sale__input-error' : $v.orderNumber.$dirty && $v.orderNumber.$error}"
                  >
                  <button
                      class="w-order-form__btn"
                      id="w-order-form__btn"
                      type="button"
                      @click="getNumberPrice"
                  >Узнать
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
          <img src="@/assets/images/edit_red.png" class="edit_header_from_admin" v-if="isEdit" @click="editElement('general')">
      </div>
    </div>
    <BackCall/>
    <City :regionName="subdomainName "/>
  </header>
</template>

<script>
import BackCall from "@/components/modal/BackCall";
import {validationMixin} from 'vuelidate'
import {required, numeric} from 'vuelidate/lib/validators'
import {mapActions, mapState} from "vuex/dist/vuex.esm.browser";
import City from "@/components/modal/City";

export default {
  name: "Header",
  mixins: [validationMixin],
  components: {City, BackCall},
  props: ['general'],
  data() {
    return {
      orderNumberModal: false,
      orderNumber: null,
      phone_1: '',
      phone_2: '',
      mobile: false,
      isEdit: false,
      isEditData: null,
      subdomainName: 'Выберите город'
    }
  },
  validations: {
    orderNumber: {
      numeric,
      required
    }
  },
  computed: {
    ...mapState([
      'userIdData',
      'userId',
      'totalPrice',
      'mobileMenu',
      'homeData',
      'compareProduct',
      'newOrders',
      'brandsData',
      'trusteds'
    ])
  },
  methods: {
    ...mapActions([
      'editFromAdmin',
      'userIdRemove',
      'userIdDataRemove',
      'mobileMenuRemove',
      'homeDataRemove',
      'compareProductRemove',
      'userTotalPriceRemove',
      'brandsDataRemove',
      'aboutDataRemove',
      'serviceDataRemove',
      'paymentShippingDataRemove',
      'leasingDataRemove',
      'contactDataRemove',
      'newOrdersRemove',
      'trustedsRemove'
    ]),

    editElement(el){
      window.open(this.isEditData[el], "MsgWindow", "width=700,height=500");
    },

    getOrderModal() {
      this.orderNumberModal = !this.orderNumberModal
      if (!this.orderNumberModal) {
        this.$v.orderNumber.$reset()
        this.orderNumber = null
      }
    },
    async getNumberPrice() {
      this.$v.orderNumber.$touch()
      if (!this.$v.orderNumber.$error) {
        const res = await this.axios.post('/order/about_order', JSON.stringify({
          "message": this.orderNumber,
        }))
        if (res.status === 200) {
          this.$v.orderNumber.$reset()
          this.orderNumber = null
          this.orderNumberModal = false
          this.$fire({
            position: 'top',
            text: "Номер заказа отправлен",
            type: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      }
    },
    totalPriceCompare() {
      let compareCount = 0
      if (this.compareProduct) {
        compareCount = this.compareProduct.length
      }
      return compareCount
    },
    getTotalPrice() {
      let total = 0
      if (this.userIdData) {
        this.userIdData.forEach((val) => {
          total += val.price * val.count
        })
        this.userTotalPriceRemove(Math.round(total))
        return Math.round(total)
      }
      this.userTotalPriceRemove(Math.round(total))

      return Math.round(this.totalPrice)
    },
    countProducts() {
      if (this.userIdData) {
        return this.userIdData.length
      }
      return 0
    },
    getPhoneNumber(number) {
      let text_1 = ''
      let text_2 = ''
      for (let i = 0; i < number.length; i++) {
        if (i < 10) {
          text_1 += number[i]
          continue;
        }
        text_2 += number[i]
      }
      return '<span>' + text_1 + '</span>' + text_2
    },
    getMobileMenu() {
      this.mobile = !this.mobileMenu
      this.mobileMenuRemove(this.mobile)
    }
  },
  watch: {
    homeData: function () {
      this.phone_1 = this.homeData.phone
      this.phone_2 = this.homeData.phone_2
      this.address = this.homeData.address
      this.subdomainName = this.homeData.subDomain
    },
  },
  async mounted() {
    if (!this.homeData) {
      this.homeDataRemove()
    }
    if (!this.newOrders.length) {
      this.newOrdersRemove()
    }
    if (!this.brandsData) {
      this.brandsDataRemove()
    }
    if (!this.trusteds) {
      this.trustedsRemove()
    }
    if (!localStorage.getItem('userId')) {
      localStorage.setItem('userId', JSON.stringify(Math.ceil(Math.random() * 1000000000)))
    }
    let userId = localStorage.getItem('userId')
    this.userIdRemove(userId)
    const res = (await this.axios.post('/card/get', JSON.stringify({id: this.userId})))
    if (res.data.card_products) {
      this.userIdDataRemove(res.data.card_products)
    }
    if (res.data.compare_products) {
      this.compareProductRemove(res.data.compare_products)
    }
    if (this.homeData) {
      this.phone_1 = this.homeData.phone
      this.phone_2 = this.homeData.phone_2
      this.address = this.homeData.address
    }
  },
  async created() {
    if (this.$route.fullPath === '/edit_page/lh9-2as7nbas_J3hNMM32') {
      this.editFromAdmin().then((res) => {
        if(res){
          this.isEdit = true
          this.isEditData = res
        }

      })
    }
  }
}
</script>

<style>
.active-nav-list {
  color: #fff !important;
}

.edit_from_admin {
  position: absolute;
  right: -25px;
  width: 20px;
  cursor: pointer;
}

.edit_header_from_admin {
  position: absolute;
  right: 20px;
  cursor: pointer;
  width: 20px;
}

.pos_rel {
  position: relative;
}

</style>
