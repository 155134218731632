<template>
  <section class="map">
    <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A6c48b6fe4ff258e235fed3fb57eaccbf9687652f7c660f7d6e206426cbf44658&amp;source=constructor"
        width="100%" height="530" frameborder="0"></iframe>
    <div class="container">
      <div
          class="text-default map__info"
          :class="{'show-text': mapText}"
          v-html="data"
      >
      </div>
      <button
          @click="mapText = !mapText"
          class="map__btn btn-more">
        {{mapText ? 'Скрыть' : 'Подробнее'}}
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "Map",
  props: ['data'],
  data () {
    return{
      mapText: false
    }
  }
}
</script>

<style>

</style>
